/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

export interface UserSetSupervisorDto {
  /** New supervisor of the user */
  supervisorUserId: string;
}

/** The state of the User invitation */
export enum UserInvitationState {
  ACTIVE = 'ACTIVE',
  PENDING_INVITATION = 'PENDING_INVITATION',
}

export interface UserHierarchyNodeDto {
  /**
   * The ID of the User
   * @example "1"
   */
  id: string;
  /**
   * The email of the User
   * @example "john@example.com"
   */
  email: string;
  /**
   * The first name of the User
   * @example "John"
   */
  firstName: string;
  /**
   * The last name of the User
   * @example "Doe"
   */
  lastName: string;
  /**
   * The full name of the User. If name is not available, email will be used instead
   * @example "John Doe"
   */
  fullName: string;
  /**
   * The state of the User invitation
   * @example "ACTIVE"
   */
  state: UserInvitationState;
  /**
   * Direct subordinates of the user
   * @example [{"email":"john@example.com","id":"uuid","subordinates":[]}]
   */
  subordinates: UserHierarchyNodeDto[];
}

export interface UserResponseDto {
  /**
   * The ID of the User
   * @example "1"
   */
  id: string;
  /**
   * The email of the User
   * @example "john@example.com"
   */
  email: string;
  /**
   * The first name of the User
   * @example "John"
   */
  firstName: string;
  /**
   * The last name of the User
   * @example "Doe"
   */
  lastName: string;
  /**
   * The full name of the User. If name is not available, email will be used instead
   * @example "John Doe"
   */
  fullName: string;
  /**
   * The state of the User invitation
   * @example "ACTIVE"
   */
  state: UserInvitationState;
}

export interface UserUpdateNameDto {
  /** First name of user */
  firstName: string;
  /** Last name of user */
  lastName: string;
}

export interface UserInvitationDto {
  /** Email of the user */
  email: string;
}

export interface UserInvitationAcceptDto {
  /** New password of the user */
  password: string;
}

export enum UserLevelAccessRole {
  UserEditor = 'user_editor',
  UserChief = 'user_chief',
  UserAdmin = 'user_admin',
}

export enum BlogLevelAccessRole {
  BlogLevelOnlyNew = 'blog_level_only_new',
  BlogLevelEditor = 'blog_level_editor',
  BlogLevelFullAccess = 'blog_level_full_access',
}

export interface BlogGroupResponseDto {
  /**
   * The unique identifier of the blog group
   * @example "0a86fe94-4bd9-4bd4-8322-6b21e536baaf"
   */
  id: string;
  /**
   * The name of the blog group
   * @example "Technology"
   */
  name: string;
}

export interface BlogLevelAccessDto {
  /** @example "blog_level_editor" */
  blogLevelAccessRole: BlogLevelAccessRole;
  blogGroup: BlogGroupResponseDto;
}

export interface UserWithPermissionsDto {
  /**
   * The ID of the User
   * @example "1"
   */
  id: string;
  /**
   * The email of the User
   * @example "john@example.com"
   */
  email: string;
  /**
   * The first name of the User
   * @example "John"
   */
  firstName: string;
  /**
   * The last name of the User
   * @example "Doe"
   */
  lastName: string;
  /**
   * The full name of the User. If name is not available, email will be used instead
   * @example "John Doe"
   */
  fullName: string;
  /**
   * The state of the User invitation
   * @example "ACTIVE"
   */
  state: UserInvitationState;
  /** @example "user_editor" */
  userLevelAccessRole: UserLevelAccessRole;
  blogLevelAccess: BlogLevelAccessDto[];
}

export interface BlogLevelAccessInputDto {
  /** @example "blog_level_editor" */
  blogLevelAccessRole: BlogLevelAccessRole;
  /** @example "0a86fe94-4bd9-4bd4-8322-6b21e536baaf" */
  blogGroupId: string;
}

export interface UserPermissionsInputDto {
  /** @example "user_editor" */
  userLevelAccessRole: UserLevelAccessRole;
  blogLevelAccess: BlogLevelAccessInputDto[];
}

export interface UserSortDto {
  /** Array of user IDs */
  users: string[];
}

export interface AuthLoginDto {
  /** Email of the user */
  email: string;
  /** Password of the user */
  password: string;
}

export interface AccessTokenDto {
  /** Access token */
  accessToken: string;
  /** Refresh token */
  refreshToken: string;
}

export interface RefreshTokenDto {
  /** Refresh token */
  refreshToken: string;
}

export interface ForgotPasswordDto {
  /** Email of the user */
  email: string;
  /** RECAPTCHA TOKEN */
  recaptchaToken: string;
}

export interface ResetPasswordDto {
  /** Reset token received in email */
  token: string;
  /** New password */
  newPassword: string;
}

export interface BlogMediumInputDto {
  /** Media file ID */
  fileId: string;
  /** Duration of this specific media */
  duration: number;
  /** Order of this specific media (the lower the number, the sooner the media appears in the list). */
  order: number;
}

/** Language of the tag translation */
export enum Language {
  En = 'en',
  Cs = 'cs',
}

export interface CreateBlogTranslationDto {
  /**
   * Language of the tag translation
   * @example "en"
   */
  language: Language;
  /**
   * Title of the blog translation
   * @example "test-title"
   */
  title: string;
  /**
   * Slug of the blog translation
   * @example "test-slug"
   */
  slug: string;
  /**
   * Content of the blog translation
   * @example "This is a test blog"
   */
  content: string;
  /** Short content (perex) of the article */
  perex?: string;
  /** Webhook IDs for this translation */
  webhookIds?: string[];
}

export interface CreateBlogDto {
  /**
   * Published date. If it is in the future, then it is planned to appear in this time. If it is null or past, it is published as long as it is approved.
   * @format date-time
   */
  publishedAt?: string;
  /** Primary media file. This can be either video or image and this is the primary thing that will be visible with the article */
  primaryMediaFileId?: string;
  /** Secondary media file. This can be image and this can be used as fallback to video, when it cannot be loaded or as a preview image for the video */
  secondaryMediaFileId?: string;
  /** Media list */
  media?: BlogMediumInputDto[];
  /**
   * Blog group ID
   * @example "123e4567-e89b-12d3-a456-426614174000"
   */
  blogGroupId: string;
  /** Translations for the blog post */
  translations: CreateBlogTranslationDto[];
  /**
   * Status of the blog post
   * @example "draft"
   */
  status: 'draft' | 'submitted' | 'approved' | 'rejected' | 'published';
  /** Tag IDs */
  tagIds?: string[];
}

/** HTTP method for the webhook */
export enum WebhookMethod {
  GET = 'GET',
  POST = 'POST',
  PUT = 'PUT',
  PATCH = 'PATCH',
  DELETE = 'DELETE',
}

/** Content type of the webhook request */
export enum WebhookContentType {
  ApplicationJson = 'application/json',
  ApplicationXWwwFormUrlencoded = 'application/x-www-form-urlencoded',
}

export interface WebhookResponseDto {
  /**
   * ID of the webhook
   * @example "123e4567-e89b-12d3-a456-426614174000"
   */
  id: string;
  /**
   * Name of the webhook
   * @example "New Blog Notification"
   */
  name: string;
  /**
   * URL of the webhook
   * @example "https://example.com/webhook"
   */
  url: string;
  /**
   * HTTP method for the webhook
   * @example "POST"
   */
  method: WebhookMethod;
  /**
   * Query parameters for the webhook
   * @example {"key":"value"}
   */
  queryParams?: object;
  /**
   * Body of the webhook request
   * @example {"key":"value"}
   */
  body?: object;
  /** Content type of the webhook request */
  contentType?: WebhookContentType;
  /**
   * Creation date of the webhook
   * @format date-time
   * @example "2024-09-24T14:03:52.000Z"
   */
  createdAt: string;
  /**
   * Last update date of the webhook
   * @format date-time
   * @example "2024-09-24T14:03:52.000Z"
   */
  updatedAt: string;
}

export interface BlogTranslationDto {
  /**
   * Language of the tag translation
   * @example "en"
   */
  language: Language;
  /**
   * Title of the blog translation
   * @example "Introduction to NestJS"
   */
  title: string;
  /**
   * Slug of the blog translation
   * @example "introduction-to-nestjs"
   */
  slug: string;
  /**
   * Content of the blog translation
   * @example "NestJS is a progressive Node.js framework..."
   */
  content: string;
  /** Short content (perex) of the article */
  perex?: string;
  /** Associated webhooks */
  webhooks: WebhookResponseDto[];
}

export interface TagGroupResponseDto {
  /**
   * The unique identifier of the tag group
   * @example "0a86fe94-4bd9-4bd4-8322-6b21e536baaf"
   */
  id: string;
  /**
   * The name of the tag group
   * @example "Programming Languages"
   */
  name: string;
}

export interface TagTranslationDto {
  /**
   * Language of the tag translation
   * @example "en"
   */
  language: Language;
  /**
   * Title of the blog translation
   * @example "Introduction to NestJS"
   */
  name: string;
}

export interface TagResponseDto {
  /**
   * ID of the tag
   * @example "12345"
   */
  id: string;
  /**
   * Color of the tag
   * @example "#000000"
   */
  color: string;
  /**
   * Slug of the tag
   * @example "introduction-to-nestjs"
   */
  slug: string;
  /** Tag group associated with this tag. */
  tagGroup: TagGroupResponseDto | null;
  /** Translations of the tag */
  translations: TagTranslationDto[];
  /**
   * Visibility of the tag
   * @example "internal"
   */
  visibility: 'internal' | 'public';
  /**
   * Creation date of the tag
   * @format date-time
   * @example "2024-09-24T14:03:52.000Z"
   */
  createdAt: string;
  /**
   * Last update date of the tag
   * @format date-time
   * @example "2024-09-24T14:03:52.000Z"
   */
  updatedAt: string;
}

/** Status of the blog post */
export enum BlogStatus {
  Draft = 'draft',
  Submitted = 'submitted',
  Approved = 'approved',
  Rejected = 'rejected',
  Published = 'published',
}

export interface FileTranslationDto {
  /**
   * ID of the file
   * @example "123e4567-e89b-12d3-a456-426614174000"
   */
  id: string;
  /**
   * Language of the tag translation
   * @example "en"
   */
  language: Language;
  /**
   * Width of image (empty for other files)
   * @example 1920
   */
  width: number | null;
  /**
   * Height of image (empty for other files)
   * @example 1080
   */
  height: number | null;
  /**
   * Duration of video (empty for other files)
   * @example 1080
   */
  duration: number | null;
  /**
   * Public URL of the file
   * @example "https://cdn.ordermage.com/image.jpg"
   */
  publicUrl: string;
  /**
   * Download pathname portion of URL of the file
   * @example "/api/files/123-1234/en"
   */
  downloadPathname: string;
}

/** Visibility of the file */
export enum FileVisibility {
  Public = 'public',
  Internal = 'internal',
}

export interface FileResponseDto {
  /**
   * ID of the file
   * @example "123e4567-e89b-12d3-a456-426614174000"
   */
  id: string;
  /**
   * File name
   * @example "image.jpg"
   */
  name: string;
  /**
   * Path of the file
   * @example "blog/assets"
   */
  path: string;
  /**
   * Mime type or "directory" if it is directory
   * @example "image/jpeg"
   */
  type: string;
  /** Translations of the file */
  translations: FileTranslationDto[];
  /**
   * Creation date of the file
   * @format date-time
   * @example "2024-09-24T14:03:52.000Z"
   */
  createdAt: string;
  /**
   * Last update date of the file
   * @format date-time
   * @example "2024-09-24T14:03:52.000Z"
   */
  updatedAt: string;
  /** Author of the file */
  author: UserResponseDto;
  /** Tags of the file */
  tags: TagResponseDto[];
  /**
   * Visibility of the file
   * @example "public"
   */
  visibility: FileVisibility;
  /**
   * Full path of the file
   * @example "blog/assets/image.jpg"
   */
  fullPath: string;
  /**
   * Parent directory id.
   * If the item is a directory, this ID represents the parent directory's ID. For standard files, this ID represents the directory where the file is located. This is null for directories inside root directory and it equals 'ROOT' in first level directory.
   * @example "123e4567-e89b-12d3-a456-426614174000"
   */
  parentDirectoryId?: string | null;
}

export interface BlogMediumResponseDto {
  /**
   * Duration of this specific media (in seconds)
   * @example 5
   */
  duration: number;
  /**
   * Order of this specific media (the lower the number, the sooner the media appears in the list).
   * @example 0
   */
  order: number;
  /** File of this media */
  file: FileResponseDto;
}

export interface BlogResponseDto {
  /**
   * ID of the blog
   * @example "12345"
   */
  id: string;
  /**
   * Blog group of the blog
   * @example "Blog group"
   */
  blogGroup: BlogGroupResponseDto;
  /**
   * Published date of the blog
   * @format date-time
   * @example "2024-09-24T14:03:52.000Z"
   */
  publishedAt: string;
  /** Translations of the blog */
  translations: BlogTranslationDto[];
  /** Tags of the blog */
  tags: TagResponseDto[];
  /**
   * Status of the blog post
   * @example "published"
   */
  status: BlogStatus;
  /**
   * Creation date of the blog
   * @format date-time
   * @example "2024-09-24T14:03:52.000Z"
   */
  createdAt: string;
  /**
   * Last update date of the blog
   * @format date-time
   * @example "2024-09-24T14:03:52.000Z"
   */
  updatedAt: string;
  /** Author of the blog (null if deleted) */
  author: UserResponseDto | null;
  /** Author of the latest update. */
  lastUpdatedBy: UserResponseDto | null;
  /** Primary media file. This can be either video or image and this is the primary thing that will be visible with the article */
  primaryMediaFile: FileResponseDto | null;
  /** Secondary media file. This can be image and this can be used as fallback to video, when it cannot be loaded or as a preview image for the video */
  secondaryMediaFile: FileResponseDto | null;
  /** Media list */
  media: BlogMediumResponseDto[];
}

export interface UpdateBlogTranslationDto {
  /**
   * Language of the tag translation
   * @example "en"
   */
  language?: Language;
  /**
   * The slug of the blog post
   * @example "my-blog-post"
   */
  slug?: string;
  /**
   * The title of the blog post
   * @example "My Blog Post"
   */
  title?: string;
  /**
   * The content of the blog post
   * @example "This is the content of my blog post"
   */
  content?: string;
  /** Short content (perex) of the article */
  perex?: string;
  /** Webhook IDs for this translation */
  webhookIds?: string[];
}

export interface UpdateBlogDto {
  /**
   * Published date. If it is in the future, then it is planned to appear in this time. If it is null or past, it is published as long as it is approved.
   * @format date-time
   */
  publishedAt?: string;
  /** Primary media file. This can be either video or image and this is the primary thing that will be visible with the article */
  primaryMediaFileId?: string;
  /** Secondary media file. This can be image and this can be used as fallback to video, when it cannot be loaded or as a preview image for the video */
  secondaryMediaFileId?: string;
  /** Media list */
  media?: BlogMediumInputDto[];
  /** The translations of the blog post */
  translations?: UpdateBlogTranslationDto[];
  /**
   * Blog group ID
   * @example "123e4567-e89b-12d3-a456-426614174000"
   */
  blogGroupId?: string;
  /**
   * Status of the blog post
   * @example "published"
   */
  status?: 'draft' | 'submitted' | 'approved' | 'rejected' | 'published';
  /** Tag IDs */
  tagIds?: string[];
}

export interface BlogListDto {
  /** Total number of items */
  totalCount: number;
  /** Array of items */
  data: BlogResponseDto[];
}

export interface BlogGroupWithBlogsDto {
  /**
   * ID of the blog group
   * @example "12345"
   */
  id: string;
  /**
   * Name of the blog group
   * @example "Technology"
   */
  name: string;
  /** List of blogs in the group */
  blogs: BlogResponseDto[];
  /**
   * Total number of blogs in the group
   * @example 42
   */
  totalBlogCount: number;
}

export enum CommentSort {
  CreatedAt = 'createdAt',
}

export enum Order {
  ASC = 'ASC',
  DESC = 'DESC',
}

export interface CommentAuthorResponseDto {
  /**
   * ID of the comment author
   * @example "123e4567-e89b-12d3-a456-426614174000"
   */
  id: string;
  /**
   * Name of the comment author
   * @example "John Doe"
   */
  name: string;
  /**
   * Ban date of the author
   * @format date-time
   * @example "2024-09-24T14:03:52.000Z"
   */
  bannedAt: string | null;
}

export interface CommentResponseDto {
  /**
   * ID of the comment
   * @example "123e4567-e89b-12d3-a456-426614174000"
   */
  id: string;
  /**
   * Creation date of the comment
   * @format date-time
   * @example "2024-09-24T14:03:52.000Z"
   */
  createdAt: string;
  /** Comment author */
  author: CommentAuthorResponseDto;
  /** Comment content */
  content: string;
  /**
   * Comment replies
   * @example []
   */
  replies: CommentResponseDto[] | null;
}

export interface CommentListDto {
  /** Total number of items */
  totalCount: number;
  /** Comment list */
  data: CommentResponseDto[];
}

export interface CreateBlogGroupDto {
  /**
   * The name of the blog group
   * @example "Technology"
   */
  name: string;
}

export interface UpdateBlogGroupDto {
  /**
   * The name of the blog group
   * @example "Technology"
   */
  name: string;
}

export interface TagPublicResponseDto {
  /**
   * ID of the tag
   * @example "12345"
   */
  id: string;
  /**
   * Color of the tag
   * @example "#000000"
   */
  color: string;
  /**
   * Slug of the tag
   * @example "introduction-to-nestjs"
   */
  slug: string;
  /** Tag group associated with this tag. */
  tagGroup: TagGroupResponseDto | null;
  /** Translation of the tag */
  translation: TagTranslationDto;
}

export interface FilePublicResponseDto {
  /**
   * ID of the file
   * @example "123e4567-e89b-12d3-a456-426614174000"
   */
  id: string;
  /**
   * File name
   * @example "image.jpg"
   */
  name: string;
  /**
   * Path of the file
   * @example "blog/assets"
   */
  path: string;
  /**
   * Mime type or "directory" if it is directory
   * @example "image/jpeg"
   */
  type: string;
  /** Translation of the file */
  translation: FileTranslationDto;
}

export interface BlogMediumPublicResponseDto {
  /**
   * Duration of this specific media (in seconds)
   * @example 5
   */
  duration: number;
  /**
   * Order of this specific media (the lower the number, the sooner the media appears in the list).
   * @example 0
   */
  order: number;
  /** File of this media */
  file: FilePublicResponseDto;
}

export interface BlogPublicResponseDto {
  /**
   * ID of the blog
   * @example "12345"
   */
  id: string;
  /**
   * Blog group of the blog
   * @example "Blog group"
   */
  blogGroup: BlogGroupResponseDto;
  /**
   * Date published
   * @format date-time
   * @example "2025-01-11T18:15:44.058Z"
   */
  publishedAt: string;
  /** Translations of the blog */
  translation: BlogTranslationDto;
  /** Tags of the blog */
  tags: TagPublicResponseDto[];
  /** Primary media file. This can be either video or image and this is the primary thing that will be visible with the article */
  primaryMediaFile: FilePublicResponseDto | null;
  /** Secondary media file. This can be image and this can be used as fallback to video, when it cannot be loaded or as a preview image for the video */
  secondaryMediaFile: FilePublicResponseDto | null;
  /** Media list */
  media: BlogMediumPublicResponseDto[];
}

export interface BlogPublicListDto {
  /** Total number of items */
  totalCount: number;
  /** Array of items */
  data: BlogPublicResponseDto[];
}

export interface BlogPublicListPreviewDto {
  /** List of all blog posts on the first page */
  list: BlogPublicListDto;
  /** Featured blog post. Null if the list with given query is empty. */
  featured?: BlogPublicResponseDto;
}

export enum BlogPublicSortBy {
  PublishedDate = 'publishedDate',
}

export interface BlogSimpleResponseDto {
  /**
   * The ID of blog
   * @example "0a86fe94-4bd9-4bd4-8322-6b21e536baaf"
   */
  id: string;
  /**
   * The name of blog
   * @example "Article"
   */
  name: string;
}

export interface CreateWebhookDto {
  /**
   * Name of the webhook
   * @example "New Blog Notification"
   */
  name: string;
  /**
   * URL of the webhook
   * @example "https://example.com/webhook"
   */
  url: string;
  /**
   * HTTP method for the webhook
   * @example "POST"
   */
  method: 'GET' | 'POST' | 'PUT' | 'PATCH' | 'DELETE';
  /**
   * Query parameters for the webhook
   * @example {"key":"value"}
   */
  queryParams?: object;
  /**
   * Body of the webhook request
   * @example {"key":"value"}
   */
  body?: object;
  /** Content type of the webhook request */
  contentType?: 'application/json' | 'application/x-www-form-urlencoded';
}

export interface UpdateWebhookDto {
  /**
   * Name of the webhook
   * @example "Updated Blog Notification"
   */
  name?: string;
  /**
   * URL of the webhook
   * @example "https://example.com/updated-webhook"
   */
  url?: string;
  /**
   * HTTP method for the webhook
   * @example "PUT"
   */
  method?: 'GET' | 'POST' | 'PUT' | 'PATCH' | 'DELETE';
  /**
   * Query parameters for the webhook
   * @example {"key":"value"}
   */
  queryParams?: object;
  /**
   * Body of the webhook request
   * @example {"key":"value"}
   */
  body?: object;
  /** Content type of the webhook request */
  contentType?: 'application/json' | 'application/x-www-form-urlencoded';
}

export interface CreateFileDto {
  /**
   * Visibility of the file
   * @example "public"
   */
  visibility?: FileVisibility;
  /**
   * Directory where to put the file
   * @example "blog/assets"
   */
  path: string;
  /** Tag IDs */
  tagIds?: string[];
  /**
   * Language of the tag translation
   * @example "en"
   */
  language?: Language;
  /** File body */
  file: object;
}

export interface FileUpdateInputDto {
  /**
   * Visibility of the file
   * @example "public"
   */
  visibility?: FileVisibility;
  /**
   * Directory where to put the file
   * @example "blog/assets"
   */
  path: string;
  /** Tag IDs */
  tagIds?: string[];
  /**
   * Name of the file
   * @example "file.jpeg"
   */
  name: string;
}

export interface UpdateFileDto {
  /** File body */
  file: object;
}

export interface CreateDirectoryDto {
  /**
   * New directory path
   * @example "/blog/assets"
   */
  path: string;
}

export interface UpdateDirectoryDto {
  /**
   * New directory name
   * @example "assets"
   */
  name: string;
  /**
   * New directory path
   * @example "assets"
   */
  path: string;
}

/** Visibility of the tag */
export enum TagVisibility {
  Internal = 'internal',
  Public = 'public',
}

export interface CreateTagTranslationDto {
  /**
   * Language of the tag translation
   * @example "en"
   */
  language: Language;
  /**
   * Name of the tag translation
   * @example "Technology"
   */
  name: string;
}

export interface CreateTagDto {
  /**
   * Color of the tag
   * @example "#FF5733"
   */
  color: string;
  /**
   * Visibility of the tag
   * @example "public"
   */
  visibility: TagVisibility;
  /**
   * Slug of the tag
   * @example "technology"
   */
  slug: string;
  /**
   * ID of the tag group
   * @example "123e4567-e89b-12d3-a456-426614174000"
   */
  tagGroupId: string;
  /** Translations for the tag */
  translations: CreateTagTranslationDto[];
}

export interface TagGroupWithTagsDto {
  /**
   * ID of the tag group
   * @example "12345"
   */
  id: string;
  /**
   * Name of the tag group
   * @example "Technology"
   */
  name: string;
  /** List of tags in the group */
  tags: TagResponseDto[];
}

export interface UpdateTagTranslationDto {
  /**
   * Language of the tag translation
   * @example "en"
   */
  language?: Language;
  /**
   * Name of the tag translation
   * @example "Technology"
   */
  name?: string;
}

export interface UpdateTagDto {
  /**
   * Color of the tag
   * @example "#FF5733"
   */
  color?: string;
  /**
   * Visibility of the tag
   * @example "public"
   */
  visibility?: 'internal' | 'public';
  /**
   * Slug of the tag
   * @example "technology"
   */
  slug?: string;
  /**
   * ID of the tag group
   * @example "123e4567-e89b-12d3-a456-426614174000"
   */
  tagGroupId?: string;
  /** Translations for the tag */
  translations?: UpdateTagTranslationDto[];
}

export interface CreateTagGroupDto {
  /**
   * The name of the tag group
   * @example "Technology"
   */
  name: string;
}

export interface TagGroupSortDto {
  /** List of tag group IDs sorted */
  ids: string[];
}

export interface UpdateTagGroupDto {
  /**
   * The name of the tag group
   * @example "Technology"
   */
  name?: string;
}

export interface TagPublicWithCountResponseDto {
  /**
   * ID of the tag
   * @example "12345"
   */
  id: string;
  /**
   * Color of the tag
   * @example "#000000"
   */
  color: string;
  /**
   * Slug of the tag
   * @example "introduction-to-nestjs"
   */
  slug: string;
  /** Tag group associated with this tag. */
  tagGroup: TagGroupResponseDto | null;
  /** Translation of the tag */
  translation: TagTranslationDto;
  /** Count of blog posts with given language */
  blogPostCount: number;
}

export interface CommentAuthorListDto {
  /** Total number of items */
  totalCount: number;
  dto: CommentAuthorResponseDto[];
}

export interface ContactDto {
  /**
   * The country of the interested person
   * @example "USA"
   */
  country: string;
  /**
   * The restaurant associated with the interested person
   * @example "Joe's Diner"
   */
  restaurant: string;
  /**
   * The email of the interested person
   * @example "john.doe@example.com"
   */
  email: string;
  /**
   * The phone number of the interested person
   * @example "+1234567890"
   */
  phone: string;
  /**
   * The service the interested person is interested in
   * @example "Catering"
   */
  service: string;
  /**
   * Captcha verification string
   * @example "03AGdBq24eHkiat7..."
   */
  captcha: string;
}

export interface CreateNewsletterContactDto {
  /**
   * Email address of the contact
   * @example "example@example.com"
   */
  email: string;
}

export enum ConfigKey {
  BentoBlogGroup = 'BentoBlogGroup',
}

export interface ConfigResponseDto {
  id: ConfigKey;
  value: object;
}

import type {
  AxiosInstance,
  AxiosRequestConfig,
  AxiosResponse,
  HeadersDefaults,
  ResponseType,
} from 'axios';
import axios from 'axios';

export type QueryParamsType = Record<string | number, any>;

export interface FullRequestParams
  extends Omit<AxiosRequestConfig, 'data' | 'params' | 'url' | 'responseType'> {
  /** set parameter to `true` for call `securityWorker` for this request */
  secure?: boolean;
  /** request path */
  path: string;
  /** content type of request body */
  type?: ContentType;
  /** query params */
  query?: QueryParamsType;
  /** format of response (i.e. response.json() -> format: "json") */
  format?: ResponseType;
  /** request body */
  body?: unknown;
}

export type RequestParams = Omit<
  FullRequestParams,
  'body' | 'method' | 'query' | 'path'
>;

export interface ApiConfig<SecurityDataType = unknown>
  extends Omit<AxiosRequestConfig, 'data' | 'cancelToken'> {
  securityWorker?: (
    securityData: SecurityDataType | null
  ) => Promise<AxiosRequestConfig | void> | AxiosRequestConfig | void;
  secure?: boolean;
  format?: ResponseType;
}

export enum ContentType {
  Json = 'application/json',
  FormData = 'multipart/form-data',
  UrlEncoded = 'application/x-www-form-urlencoded',
  Text = 'text/plain',
}

export class HttpClient<SecurityDataType = unknown> {
  public instance: AxiosInstance;
  private securityData: SecurityDataType | null = null;
  private securityWorker?: ApiConfig<SecurityDataType>['securityWorker'];
  private secure?: boolean;
  private format?: ResponseType;

  constructor({
    securityWorker,
    secure,
    format,
    ...axiosConfig
  }: ApiConfig<SecurityDataType> = {}) {
    this.instance = axios.create({
      ...axiosConfig,
      baseURL: axiosConfig.baseURL || '',
    });
    this.secure = secure;
    this.format = format;
    this.securityWorker = securityWorker;
  }

  public setSecurityData = (data: SecurityDataType | null) => {
    this.securityData = data;
  };

  protected mergeRequestParams(
    params1: AxiosRequestConfig,
    params2?: AxiosRequestConfig
  ): AxiosRequestConfig {
    const method = params1.method || (params2 && params2.method);

    return {
      ...this.instance.defaults,
      ...params1,
      ...(params2 || {}),
      headers: {
        ...((method &&
          this.instance.defaults.headers[
            method.toLowerCase() as keyof HeadersDefaults
          ]) ||
          {}),
        ...(params1.headers || {}),
        ...((params2 && params2.headers) || {}),
      },
    };
  }

  protected stringifyFormItem(formItem: unknown) {
    if (typeof formItem === 'object' && formItem !== null) {
      return JSON.stringify(formItem);
    } else {
      return `${formItem}`;
    }
  }

  protected createFormData(input: Record<string, unknown>): FormData {
    if (input instanceof FormData) {
      return input;
    }
    return Object.keys(input || {}).reduce((formData, key) => {
      const property = input[key];
      const propertyContent: any[] =
        property instanceof Array ? property : [property];

      for (const formItem of propertyContent) {
        const isFileType = formItem instanceof Blob || formItem instanceof File;
        formData.append(
          key,
          isFileType ? formItem : this.stringifyFormItem(formItem)
        );
      }

      return formData;
    }, new FormData());
  }

  public request = async <T = any, _E = any>({
    secure,
    path,
    type,
    query,
    format,
    body,
    ...params
  }: FullRequestParams): Promise<AxiosResponse<T>> => {
    const secureParams =
      ((typeof secure === 'boolean' ? secure : this.secure) &&
        this.securityWorker &&
        (await this.securityWorker(this.securityData))) ||
      {};
    const requestParams = this.mergeRequestParams(params, secureParams);
    const responseFormat = format || this.format || undefined;

    if (
      type === ContentType.FormData &&
      body &&
      body !== null &&
      typeof body === 'object'
    ) {
      body = this.createFormData(body as Record<string, unknown>);
    }

    if (
      type === ContentType.Text &&
      body &&
      body !== null &&
      typeof body !== 'string'
    ) {
      body = JSON.stringify(body);
    }

    return this.instance.request({
      ...requestParams,
      headers: {
        ...(requestParams.headers || {}),
        ...(type ? { 'Content-Type': type } : {}),
      },
      params: query,
      responseType: responseFormat,
      data: body,
      url: path,
    });
  };
}

/**
 * @title Blog CMS API
 * @version 1.0
 * @contact
 *
 * The API for the Blog CMS
 */
export class Api<
  SecurityDataType extends unknown,
> extends HttpClient<SecurityDataType> {
  api = {
    /**
     * @description This operation sets proper supervisor-subordinate relationship between user. If subordinate has another supervisor, it is overwritten.
     *
     * @tags users
     * @name UserControllerSetSupervisor
     * @summary Set supervisor to user
     * @request PATCH:/api/users/{id}/supervisor
     * @secure
     */
    userControllerSetSupervisor: (
      id: string,
      data: UserSetSupervisorDto,
      params: RequestParams = {}
    ) =>
      this.request<void, void>({
        path: `/api/users/${id}/supervisor`,
        method: 'PATCH',
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags users
     * @name UserControllerGetUserHierarchy
     * @summary Retrieve all users in hierarchic (tree) representation.
     * @request GET:/api/users/hierarchy
     * @secure
     */
    userControllerGetUserHierarchy: (params: RequestParams = {}) =>
      this.request<UserHierarchyNodeDto[], any>({
        path: `/api/users/hierarchy`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags users
     * @name UserControllerFindAll
     * @summary Retrieve all users
     * @request GET:/api/users
     * @secure
     */
    userControllerFindAll: (params: RequestParams = {}) =>
      this.request<UserResponseDto[], any>({
        path: `/api/users`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags users
     * @name UserControllerGetCurrent
     * @summary Retrieve current user
     * @request GET:/api/users/me
     * @secure
     */
    userControllerGetCurrent: (params: RequestParams = {}) =>
      this.request<UserResponseDto, any>({
        path: `/api/users/me`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags users
     * @name UserControllerSetUserName
     * @summary Set current user name
     * @request PATCH:/api/users/me/name
     * @secure
     */
    userControllerSetUserName: (
      data: UserUpdateNameDto,
      params: RequestParams = {}
    ) =>
      this.request<UserResponseDto, any>({
        path: `/api/users/me/name`,
        method: 'PATCH',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags users
     * @name UserControllerFindOne
     * @summary Retrieve a user by ID
     * @request GET:/api/users/{id}
     * @secure
     */
    userControllerFindOne: (id: string, params: RequestParams = {}) =>
      this.request<UserResponseDto, any>({
        path: `/api/users/${id}`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * @description Uses soft delete technique, therefore the data will remain in the database.
     *
     * @tags users
     * @name UserControllerDeleteUser
     * @summary Deletes user from the system
     * @request DELETE:/api/users/{id}
     * @secure
     */
    userControllerDeleteUser: (id: string, params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/api/users/${id}`,
        method: 'DELETE',
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags users
     * @name UserControllerInviteUser
     * @summary Invites a new user to the application by sending an email with invitation link. Invitation is valid 7 days.
     * @request POST:/api/users/invitations
     * @secure
     */
    userControllerInviteUser: (
      data: UserInvitationDto,
      params: RequestParams = {}
    ) =>
      this.request<void, void>({
        path: `/api/users/invitations`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags users
     * @name UserControllerInvitationExists
     * @summary Detection for whether the invitation exists and is valid
     * @request HEAD:/api/users/invitations/{token}
     * @secure
     */
    userControllerInvitationExists: (
      token: string,
      params: RequestParams = {}
    ) =>
      this.request<void, void>({
        path: `/api/users/invitations/${token}`,
        method: 'HEAD',
        secure: true,
        ...params,
      }),

    /**
     * @description This operation transforms invitation to user and from this point, user can log in using credentials they chose.
     *
     * @tags users
     * @name UserControllerAcceptInvitation
     * @summary Accept existing valid invitation
     * @request POST:/api/users/invitations/{token}/accept
     * @secure
     */
    userControllerAcceptInvitation: (
      token: string,
      data: UserInvitationAcceptDto,
      params: RequestParams = {}
    ) =>
      this.request<UserResponseDto, void>({
        path: `/api/users/invitations/${token}/accept`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags users
     * @name UserControllerFindPermissions
     * @summary Retrieve user permissions by ID
     * @request GET:/api/users/{id}/permissions
     * @secure
     */
    userControllerFindPermissions: (id: string, params: RequestParams = {}) =>
      this.request<UserWithPermissionsDto, void>({
        path: `/api/users/${id}/permissions`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags users
     * @name UserControllerUpsertPermissions
     * @summary Update user permissions
     * @request PUT:/api/users/{id}/permissions
     * @secure
     */
    userControllerUpsertPermissions: (
      id: string,
      data: UserPermissionsInputDto,
      params: RequestParams = {}
    ) =>
      this.request<UserWithPermissionsDto, void>({
        path: `/api/users/${id}/permissions`,
        method: 'PUT',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags users
     * @name UserControllerSortUsers
     * @summary Sort users based on the given order
     * @request POST:/api/users/sort
     * @secure
     */
    userControllerSortUsers: (data: UserSortDto, params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/api/users/sort`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags auth
     * @name AuthControllerSignIn
     * @summary Sign in
     * @request POST:/api/auth/login
     */
    authControllerSignIn: (data: AuthLoginDto, params: RequestParams = {}) =>
      this.request<AccessTokenDto, void>({
        path: `/api/auth/login`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags auth
     * @name AuthControllerRefreshTokens
     * @summary Refresh tokens
     * @request POST:/api/auth/refresh
     */
    authControllerRefreshTokens: (
      data: RefreshTokenDto,
      params: RequestParams = {}
    ) =>
      this.request<AccessTokenDto, void>({
        path: `/api/auth/refresh`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags auth
     * @name AuthControllerLogout
     * @summary Logout from all devices
     * @request POST:/api/auth/logout
     */
    authControllerLogout: (params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/api/auth/logout`,
        method: 'POST',
        ...params,
      }),

    /**
     * No description
     *
     * @tags auth
     * @name AuthControllerForgotPassword
     * @summary Request password reset
     * @request POST:/api/auth/forgot-password
     */
    authControllerForgotPassword: (
      data: ForgotPasswordDto,
      params: RequestParams = {}
    ) =>
      this.request<void, any>({
        path: `/api/auth/forgot-password`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags auth
     * @name AuthControllerResetPassword
     * @summary Reset password
     * @request POST:/api/auth/reset-password
     */
    authControllerResetPassword: (
      data: ResetPasswordDto,
      params: RequestParams = {}
    ) =>
      this.request<void, void>({
        path: `/api/auth/reset-password`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags blogs
     * @name BlogControllerCreateBlog
     * @summary Create a new blog post with translations
     * @request POST:/api/blogs
     * @secure
     */
    blogControllerCreateBlog: (
      data: CreateBlogDto,
      params: RequestParams = {}
    ) =>
      this.request<BlogResponseDto, void>({
        path: `/api/blogs`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags blogs
     * @name BlogControllerUpdateBlog
     * @summary Update an existing blog post with translations
     * @request PUT:/api/blogs/{id}
     * @secure
     */
    blogControllerUpdateBlog: (
      id: string,
      data: UpdateBlogDto,
      params: RequestParams = {}
    ) =>
      this.request<BlogResponseDto, any>({
        path: `/api/blogs/${id}`,
        method: 'PUT',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags blogs
     * @name BlogControllerDeleteBlog
     * @summary Delete a blog post by ID
     * @request DELETE:/api/blogs/{id}
     * @secure
     */
    blogControllerDeleteBlog: (id: string, params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/api/blogs/${id}`,
        method: 'DELETE',
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags blogs
     * @name BlogControllerGetBlog
     * @summary Get a blog post by ID
     * @request GET:/api/blogs/{id}
     * @secure
     */
    blogControllerGetBlog: (id: string, params: RequestParams = {}) =>
      this.request<BlogResponseDto, void>({
        path: `/api/blogs/${id}`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags blogs
     * @name BlogControllerGetSubmittedBlogs
     * @summary Get paginated list of submitted blog posts for approval
     * @request GET:/api/blogs/approval
     * @secure
     */
    blogControllerGetSubmittedBlogs: (
      query?: {
        /** @default 0 */
        page?: number;
        /** @default 10 */
        limit?: number;
      },
      params: RequestParams = {}
    ) =>
      this.request<BlogListDto, void>({
        path: `/api/blogs/approval`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags blogs
     * @name BlogControllerGetApprovedBlogs
     * @summary Get paginated list of approved blog posts waiting for publishing
     * @request GET:/api/blogs/approved
     * @secure
     */
    blogControllerGetApprovedBlogs: (
      query?: {
        /** @default 0 */
        page?: number;
        /** @default 10 */
        limit?: number;
      },
      params: RequestParams = {}
    ) =>
      this.request<BlogListDto, void>({
        path: `/api/blogs/approved`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags blogs
     * @name BlogControllerGetBlogGroupsWithBlogs
     * @summary Get all blog groups with specified number of blogs
     * @request GET:/api/blogs/groups
     * @secure
     */
    blogControllerGetBlogGroupsWithBlogs: (
      query?: {
        /**
         * Number of blogs to return per group
         * @default 5
         */
        blogLimit?: number;
      },
      params: RequestParams = {}
    ) =>
      this.request<BlogGroupWithBlogsDto[], void>({
        path: `/api/blogs/groups`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags blogs
     * @name BlogControllerCheckIfSlugExists
     * @summary Check if blog with specified slug exists
     * @request HEAD:/api/blogs/{slug}/{language}
     * @secure
     */
    blogControllerCheckIfSlugExists: (
      slug: string,
      language: Language,
      query?: {
        exceptId?: string;
      },
      params: RequestParams = {}
    ) =>
      this.request<void, void>({
        path: `/api/blogs/${slug}/${language}`,
        method: 'HEAD',
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags blogs
     * @name BlogControllerApproveBlog
     * @summary Approve a submitted blog post
     * @request PATCH:/api/blogs/{id}/approve
     * @secure
     */
    blogControllerApproveBlog: (id: string, params: RequestParams = {}) =>
      this.request<BlogResponseDto, void>({
        path: `/api/blogs/${id}/approve`,
        method: 'PATCH',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags blogs
     * @name BlogControllerRejectBlog
     * @summary Reject a submitted blog post
     * @request PATCH:/api/blogs/{id}/reject
     * @secure
     */
    blogControllerRejectBlog: (id: string, params: RequestParams = {}) =>
      this.request<BlogResponseDto, void>({
        path: `/api/blogs/${id}/reject`,
        method: 'PATCH',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags blogs
     * @name BlogControllerGetBlogsByGroup
     * @summary Get paginated list of blogs for a specific blog group
     * @request GET:/api/blogs/group/{groupId}
     * @secure
     */
    blogControllerGetBlogsByGroup: (
      groupId: string,
      query?: {
        /** @default 0 */
        page?: number;
        /** @default 10 */
        limit?: number;
      },
      params: RequestParams = {}
    ) =>
      this.request<BlogListDto, void>({
        path: `/api/blogs/group/${groupId}`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags blogs
     * @name BlogControllerFindAllComments
     * @summary Get all comments of blog
     * @request GET:/api/blogs/{id}/comments
     * @secure
     */
    blogControllerFindAllComments: (
      id: string,
      query: {
        /** @default 0 */
        page?: number;
        /** @default 10 */
        limit?: number;
        sortBy: CommentSort;
        order?: Order;
      },
      params: RequestParams = {}
    ) =>
      this.request<CommentListDto, any>({
        path: `/api/blogs/${id}/comments`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags blog-groups
     * @name BlogGroupControllerCreate
     * @summary Create a new blog group
     * @request POST:/api/blog-groups
     * @secure
     */
    blogGroupControllerCreate: (
      data: CreateBlogGroupDto,
      params: RequestParams = {}
    ) =>
      this.request<BlogGroupResponseDto, any>({
        path: `/api/blog-groups`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags blog-groups
     * @name BlogGroupControllerFindAll
     * @summary Get all blog groups
     * @request GET:/api/blog-groups
     * @secure
     */
    blogGroupControllerFindAll: (params: RequestParams = {}) =>
      this.request<BlogGroupResponseDto[], any>({
        path: `/api/blog-groups`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags blog-groups
     * @name BlogGroupControllerUpdate
     * @summary Update a blog group
     * @request PUT:/api/blog-groups/{id}
     * @secure
     */
    blogGroupControllerUpdate: (
      id: string,
      data: UpdateBlogGroupDto,
      params: RequestParams = {}
    ) =>
      this.request<BlogGroupResponseDto, void>({
        path: `/api/blog-groups/${id}`,
        method: 'PUT',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags blog-groups
     * @name BlogGroupControllerRemove
     * @summary Delete a blog group
     * @request DELETE:/api/blog-groups/{id}
     * @secure
     */
    blogGroupControllerRemove: (id: string, params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/api/blog-groups/${id}`,
        method: 'DELETE',
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags blogs, blogs-public
     * @name BlogPublicControllerGetBlogsPreview
     * @summary Get paginated list of blog posts with featured blog post
     * @request GET:/api/public/blogs/preview
     */
    blogPublicControllerGetBlogsPreview: (
      query?: {
        /** Filters blog posts by specific tags. */
        tagId?: string[];
        /** Filters blog posts by its blog group. */
        blogGroupId?: string;
        /** Excludes blog posts by specific blog group. */
        excludeBlogGroupId?: string;
      },
      params: RequestParams = {}
    ) =>
      this.request<BlogPublicListPreviewDto, void>({
        path: `/api/public/blogs/preview`,
        method: 'GET',
        query: query,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags blogs, blogs-public
     * @name BlogPublicControllerGetBlog
     * @summary Get a blog post by slug
     * @request GET:/api/public/blogs/{slug}
     */
    blogPublicControllerGetBlog: (slug: string, params: RequestParams = {}) =>
      this.request<BlogPublicResponseDto, void>({
        path: `/api/public/blogs/${slug}`,
        method: 'GET',
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags blogs, blogs-public
     * @name BlogPublicControllerGetBlogs
     * @summary Get paginated list of blog posts
     * @request GET:/api/public/blogs
     */
    blogPublicControllerGetBlogs: (
      query?: {
        /** @default 0 */
        page?: number;
        /** @default 10 */
        limit?: number;
        sortBy?: BlogPublicSortBy;
        order?: Order;
        /** Filters blog posts by specific tags. If this parameter is present, it can take featured blog post into account based on excludeFeatured param. */
        tagId?: string[];
        /** Filters blog posts by its blog group. If this parameter is present, it can take featured blog post into account based on excludeFeatured param. */
        blogGroupId?: string;
        /** Excludes blog posts by specific blog group. */
        excludeBlogGroupId?: string;
        /** Excludes blog posts by specific id. */
        excludeBlogId?: string;
        /**
         * If set to false, includes also featured blog post into result (behaves as standard pagination). If set to true, it will never include featured blog post into account.
         * @default "true"
         */
        excludeFeatured?: string;
      },
      params: RequestParams = {}
    ) =>
      this.request<BlogPublicListDto, void>({
        path: `/api/public/blogs`,
        method: 'GET',
        query: query,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags blog-files
     * @name BlogFilesControllerGetFileBlogs
     * @summary Retrieves simple blogs where this file is used.
     * @request GET:/api/files/{id}/blogs
     * @secure
     */
    blogFilesControllerGetFileBlogs: (id: string, params: RequestParams = {}) =>
      this.request<BlogSimpleResponseDto[], void>({
        path: `/api/files/${id}/blogs`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags webhooks
     * @name WebhookControllerCreate
     * @summary Create a new webhook
     * @request POST:/api/webhooks
     * @secure
     */
    webhookControllerCreate: (
      data: CreateWebhookDto,
      params: RequestParams = {}
    ) =>
      this.request<WebhookResponseDto, any>({
        path: `/api/webhooks`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags webhooks
     * @name WebhookControllerFindAll
     * @summary Get all webhooks
     * @request GET:/api/webhooks
     * @secure
     */
    webhookControllerFindAll: (params: RequestParams = {}) =>
      this.request<WebhookResponseDto[], any>({
        path: `/api/webhooks`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags webhooks
     * @name WebhookControllerFindOne
     * @summary Get a webhook by id
     * @request GET:/api/webhooks/{id}
     * @secure
     */
    webhookControllerFindOne: (id: string, params: RequestParams = {}) =>
      this.request<WebhookResponseDto, void>({
        path: `/api/webhooks/${id}`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags webhooks
     * @name WebhookControllerUpdate
     * @summary Update a webhook
     * @request PUT:/api/webhooks/{id}
     * @secure
     */
    webhookControllerUpdate: (
      id: string,
      data: UpdateWebhookDto,
      params: RequestParams = {}
    ) =>
      this.request<WebhookResponseDto, void>({
        path: `/api/webhooks/${id}`,
        method: 'PUT',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags webhooks
     * @name WebhookControllerRemove
     * @summary Delete a webhook
     * @request DELETE:/api/webhooks/{id}
     * @secure
     */
    webhookControllerRemove: (id: string, params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/api/webhooks/${id}`,
        method: 'DELETE',
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags files
     * @name FileControllerCreateNewFile
     * @summary Create a new file with default translation
     * @request POST:/api/files
     * @secure
     */
    fileControllerCreateNewFile: (
      data: CreateFileDto,
      params: RequestParams = {}
    ) =>
      this.request<FileResponseDto, void>({
        path: `/api/files`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.FormData,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags files
     * @name FileControllerUpdateFile
     * @summary Updates a metadata of existing file
     * @request PUT:/api/files/{id}
     * @secure
     */
    fileControllerUpdateFile: (
      id: string,
      data: FileUpdateInputDto,
      params: RequestParams = {}
    ) =>
      this.request<FileResponseDto, any>({
        path: `/api/files/${id}`,
        method: 'PUT',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags files
     * @name FileControllerDeleteFile
     * @summary Deletes file
     * @request DELETE:/api/files/{id}
     * @secure
     */
    fileControllerDeleteFile: (id: string, params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/api/files/${id}`,
        method: 'DELETE',
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags files
     * @name FileControllerGetFile
     * @summary Retrieves single file
     * @request GET:/api/files/{id}
     * @secure
     */
    fileControllerGetFile: (id: string, params: RequestParams = {}) =>
      this.request<FileResponseDto, void>({
        path: `/api/files/${id}`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * @description Works as upsert. File translation is created if language is not present within the file translations. If the translation is present, it is updated.
     *
     * @tags files
     * @name FileControllerUpsertFileTranslation
     * @summary Updates a file with provided translation
     * @request PUT:/api/files/{id}/translation/{language}
     * @secure
     */
    fileControllerUpsertFileTranslation: (
      id: string,
      language: Language,
      data: UpdateFileDto,
      params: RequestParams = {}
    ) =>
      this.request<FileResponseDto, void>({
        path: `/api/files/${id}/translation/${language}`,
        method: 'PUT',
        body: data,
        secure: true,
        type: ContentType.FormData,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags files
     * @name FileControllerDeleteFileTranslation
     * @summary Deletes file translation
     * @request DELETE:/api/files/{id}/translation/{language}
     * @secure
     */
    fileControllerDeleteFileTranslation: (
      id: string,
      language: string,
      params: RequestParams = {}
    ) =>
      this.request<void, void>({
        path: `/api/files/${id}/translation/${language}`,
        method: 'DELETE',
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags directories
     * @name DirectoryControllerScanRootDir
     * @summary Retrieves list of directories and files in root directory
     * @request GET:/api/directories
     * @secure
     */
    directoryControllerScanRootDir: (path?: any, params: RequestParams = {}) =>
      this.request<FileResponseDto[], any>({
        path: `/api/directories`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags directories
     * @name DirectoryControllerCreateDirectory
     * @summary Creates empty directory
     * @request POST:/api/directories
     * @secure
     */
    directoryControllerCreateDirectory: (
      data: CreateDirectoryDto,
      params: RequestParams = {}
    ) =>
      this.request<FileResponseDto, void>({
        path: `/api/directories`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags directories
     * @name DirectoryControllerScanDir
     * @summary Retrieves list of directories and files in specific directory
     * @request GET:/api/directories/{id}
     * @secure
     */
    directoryControllerScanDir: (id: string, params: RequestParams = {}) =>
      this.request<FileResponseDto[], void>({
        path: `/api/directories/${id}`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags directories
     * @name DirectoryControllerUpdateDirectory
     * @summary Updates directory name
     * @request PUT:/api/directories/{id}
     * @secure
     */
    directoryControllerUpdateDirectory: (
      id: string,
      data: UpdateDirectoryDto,
      params: RequestParams = {}
    ) =>
      this.request<FileResponseDto, void>({
        path: `/api/directories/${id}`,
        method: 'PUT',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags directories
     * @name DirectoryControllerDeleteDirectory
     * @summary Deletes directory with all subdirectories and files
     * @request DELETE:/api/directories/{id}
     * @secure
     */
    directoryControllerDeleteDirectory: (
      id: string,
      params: RequestParams = {}
    ) =>
      this.request<void, void>({
        path: `/api/directories/${id}`,
        method: 'DELETE',
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags files-public
     * @name FilePublicControllerDownloadFile
     * @summary Downloads a file by id and language
     * @request GET:/api/files/{id}/{language}
     */
    filePublicControllerDownloadFile: (
      id: string,
      language: Language,
      params: RequestParams = {}
    ) =>
      this.request<void, any>({
        path: `/api/files/${id}/${language}`,
        method: 'GET',
        ...params,
      }),

    /**
     * No description
     *
     * @tags tags
     * @name TagControllerCreate
     * @summary Create a new tag
     * @request POST:/api/tags
     * @secure
     */
    tagControllerCreate: (data: CreateTagDto, params: RequestParams = {}) =>
      this.request<TagResponseDto, any>({
        path: `/api/tags`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags tags
     * @name TagControllerFindAll
     * @summary Get all tags
     * @request GET:/api/tags
     * @secure
     */
    tagControllerFindAll: (params: RequestParams = {}) =>
      this.request<TagResponseDto[], any>({
        path: `/api/tags`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags tags
     * @name TagControllerFindAllWithGroups
     * @summary Get all tags with their groups
     * @request GET:/api/tags/groups
     * @secure
     */
    tagControllerFindAllWithGroups: (params: RequestParams = {}) =>
      this.request<TagGroupWithTagsDto[], any>({
        path: `/api/tags/groups`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags tags
     * @name TagControllerFindOne
     * @summary Get a tag by id
     * @request GET:/api/tags/{id}
     * @secure
     */
    tagControllerFindOne: (id: string, params: RequestParams = {}) =>
      this.request<TagResponseDto, void>({
        path: `/api/tags/${id}`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags tags
     * @name TagControllerUpdate
     * @summary Update a tag
     * @request PUT:/api/tags/{id}
     * @secure
     */
    tagControllerUpdate: (
      id: string,
      data: UpdateTagDto,
      params: RequestParams = {}
    ) =>
      this.request<TagResponseDto, void>({
        path: `/api/tags/${id}`,
        method: 'PUT',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags tags
     * @name TagControllerRemove
     * @summary Delete a tag
     * @request DELETE:/api/tags/{id}
     * @secure
     */
    tagControllerRemove: (id: string, params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/api/tags/${id}`,
        method: 'DELETE',
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags tag-groups
     * @name TagGroupControllerCreate
     * @summary Create a new tag group
     * @request POST:/api/tag-groups
     * @secure
     */
    tagGroupControllerCreate: (
      data: CreateTagGroupDto,
      params: RequestParams = {}
    ) =>
      this.request<TagGroupResponseDto, any>({
        path: `/api/tag-groups`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags tag-groups
     * @name TagGroupControllerFindAll
     * @summary Get all tag groups
     * @request GET:/api/tag-groups
     * @secure
     */
    tagGroupControllerFindAll: (params: RequestParams = {}) =>
      this.request<TagGroupResponseDto[], any>({
        path: `/api/tag-groups`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags tag-groups
     * @name TagGroupControllerUpdateSort
     * @summary Update the sort order of a tag groups
     * @request PUT:/api/tag-groups/sort
     * @secure
     */
    tagGroupControllerUpdateSort: (
      data: TagGroupSortDto,
      params: RequestParams = {}
    ) =>
      this.request<TagGroupResponseDto[], void>({
        path: `/api/tag-groups/sort`,
        method: 'PUT',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags tag-groups
     * @name TagGroupControllerFindOne
     * @summary Get a tag group by id
     * @request GET:/api/tag-groups/{id}
     * @secure
     */
    tagGroupControllerFindOne: (id: string, params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/api/tag-groups/${id}`,
        method: 'GET',
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags tag-groups
     * @name TagGroupControllerUpdate
     * @summary Update a tag group
     * @request PUT:/api/tag-groups/{id}
     * @secure
     */
    tagGroupControllerUpdate: (
      id: string,
      data: UpdateTagGroupDto,
      params: RequestParams = {}
    ) =>
      this.request<TagGroupResponseDto, void>({
        path: `/api/tag-groups/${id}`,
        method: 'PUT',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags tag-groups
     * @name TagGroupControllerRemove
     * @summary Delete a tag group
     * @request DELETE:/api/tag-groups/{id}
     * @secure
     */
    tagGroupControllerRemove: (id: string, params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/api/tag-groups/${id}`,
        method: 'DELETE',
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags tags, tags-public
     * @name TagPublicControllerGetAll
     * @summary Get all tags
     * @request GET:/api/public/tags
     */
    tagPublicControllerGetAll: (
      query?: {
        /** Excludes blog count by specific blog group. */
        excludeBlogGroupId?: string;
      },
      params: RequestParams = {}
    ) =>
      this.request<TagPublicWithCountResponseDto[], any>({
        path: `/api/public/tags`,
        method: 'GET',
        query: query,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags comments
     * @name CommentControllerFindOne
     * @summary Get a comment by ID
     * @request GET:/api/comments/{id}
     * @secure
     */
    commentControllerFindOne: (id: string, params: RequestParams = {}) =>
      this.request<CommentResponseDto, void>({
        path: `/api/comments/${id}`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags comments
     * @name CommentControllerRemove
     * @summary Delete a comment
     * @request DELETE:/api/comments/{id}
     * @secure
     */
    commentControllerRemove: (id: string, params: RequestParams = {}) =>
      this.request<CommentResponseDto, void>({
        path: `/api/comments/${id}`,
        method: 'DELETE',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags comments-authors
     * @name CommentAuthorControllerListAllAuthors
     * @summary List all authors
     * @request GET:/api/comments/authors
     * @secure
     */
    commentAuthorControllerListAllAuthors: (
      query?: {
        /** @default 0 */
        page?: number;
        /** @default 10 */
        limit?: number;
      },
      params: RequestParams = {}
    ) =>
      this.request<CommentAuthorListDto, any>({
        path: `/api/comments/authors`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags comments-authors
     * @name CommentAuthorControllerUnbanAuthor
     * @summary Bans a comment author
     * @request PUT:/api/comments/authors/{id}/unban
     * @secure
     */
    commentAuthorControllerUnbanAuthor: (
      id: string,
      params: RequestParams = {}
    ) =>
      this.request<void, void>({
        path: `/api/comments/authors/${id}/unban`,
        method: 'PUT',
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags comments-authors
     * @name CommentAuthorControllerBanAuthor
     * @summary Bans a comment author
     * @request PUT:/api/comments/authors/{id}/ban
     * @secure
     */
    commentAuthorControllerBanAuthor: (
      id: string,
      params: RequestParams = {}
    ) =>
      this.request<void, void>({
        path: `/api/comments/authors/${id}/ban`,
        method: 'PUT',
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags contacts
     * @name ContactControllerContactSales
     * @summary Send contact email
     * @request POST:/api/contacts/sales
     */
    contactControllerContactSales: (
      data: ContactDto,
      params: RequestParams = {}
    ) =>
      this.request<void, void>({
        path: `/api/contacts/sales`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags newsletter
     * @name NewsletterControllerCreateContact
     * @summary Create a new contact. If the contact already exists, no action will be taken.
     * @request POST:/api/newsletter/contacts
     */
    newsletterControllerCreateContact: (
      data: CreateNewsletterContactDto,
      params: RequestParams = {}
    ) =>
      this.request<void, any>({
        path: `/api/newsletter/contacts`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags newsletter
     * @name NewsletterControllerListContacts
     * @summary List all contacts
     * @request GET:/api/newsletter/contacts
     */
    newsletterControllerListContacts: (
      query?: {
        /** @default 0 */
        page?: number;
        /** @default 10 */
        limit?: number;
      },
      params: RequestParams = {}
    ) =>
      this.request<void, any>({
        path: `/api/newsletter/contacts`,
        method: 'GET',
        query: query,
        ...params,
      }),

    /**
     * No description
     *
     * @tags config
     * @name ConfigControllerGetConfig
     * @summary Get public config
     * @request GET:/api/config
     */
    configControllerGetConfig: (params: RequestParams = {}) =>
      this.request<ConfigResponseDto[], any>({
        path: `/api/config`,
        method: 'GET',
        format: 'json',
        ...params,
      }),
  };
}
