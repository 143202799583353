import { TagGroupWithTagsDto } from '@admin/features/api/generated';
import React from 'react';
import {
  SortableContext,
  verticalListSortingStrategy,
} from '@dnd-kit/sortable';
import {
  TagsGalleryGroup,
  TagsGalleryGroupSortable,
} from '@admin/features/blogs/BlogEditor/modals/TagsGalleryModal/TagsGalleryGroup';
import { DragOverlay } from '@dnd-kit/core';
import { usePermissions } from '@admin/features/auth/usePermissions';

type TagsGalleryGroupProps = {
  groups: TagGroupWithTagsDto[];
  activeId?: string | null;
};

export const TagsGalleryGroups: React.FC<TagsGalleryGroupProps> = ({
  groups,
  activeId,
}) => {
  const { isEditor } = usePermissions();
  return (
    <SortableContext
      disabled={isEditor}
      items={groups}
      strategy={verticalListSortingStrategy}
      id='group-sort'
    >
      {groups.map((group) => (
        <TagsGalleryGroupSortable key={group.id} group={group} />
      ))}
      <DragOverlay>
        {activeId ? (
          <TagsGalleryGroup
            group={groups.find((g) => g.id === activeId)!}
            translucent
          />
        ) : null}
      </DragOverlay>
    </SortableContext>
  );
};
