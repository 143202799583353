import { Tag } from '@admin/features/blogs/components/Tag/Tag';
import React from 'react';
import {
  TagGroupWithTagsDto,
  TagResponseDto,
} from '@admin/features/api/generated';
import { useFormContext } from 'react-hook-form';
import { TAG_IDS_NAME } from '@admin/features/blogs/BlogEditor/modals/TagsSearchModal/TagsSearchModal';
import { useModal } from '@admin/features/layout/modals/useModal';
import { CreateTagModal } from '@admin/features/blogs/BlogEditor/modals/CreateTagModal/CreateTagModal';
import { RemoveTagModal } from '@admin/features/blogs/BlogEditor/modals/RemoveTagModal/RemoveTagModal';
import { toggleValue } from '@admin/utils';
import { DroppableTagsArea } from '@admin/features/theme/components/DroppableTagsArea/DroppableTagsArea';
import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { FaEllipsisVertical, FaTrash } from 'react-icons/fa6';
import { cn } from '@admin/utils/cn';
import { Button } from '@admin/features/theme/components/Button/Button';
import { FaEdit } from 'react-icons/fa';
import { RemoveTagGroupModal } from '@admin/features/blogs/BlogEditor/modals/RemoveTagGroupModal/RemoveTagGroupModal';
import { TagGroupFormModal } from '@admin/features/blogs/BlogEditor/modals/TagGroupFormModal/TagGroupFormModal';
import { usePermissions } from '@admin/features/auth/usePermissions';

export type TagsGalleryGroupProps = {
  group: TagGroupWithTagsDto;
  translucent?: boolean;
};

export const TagsGalleryGroup = ({
  group,
  translucent,
}: TagsGalleryGroupProps) => {
  const { watch, setValue } = useFormContext();

  const selectedTagIds: string[] = watch(TAG_IDS_NAME);

  const { openModal } = useModal();

  const updateTagHandler = (tag: TagResponseDto): void => {
    openModal(<CreateTagModal tag={tag} />);
  };

  const removeTagHandler = (tag: TagResponseDto): void => {
    openModal(<RemoveTagModal tag={tag} />);
  };

  const removeTagGroupHandler = (): void => {
    openModal(<RemoveTagGroupModal tagGroup={group} />);
  };

  const openUpdateTagGroupModal = (): void => {
    openModal(<TagGroupFormModal tagGroup={group} />);
  };

  const toggleTagHandler = (tag: TagResponseDto): void => {
    setValue(TAG_IDS_NAME, toggleValue(selectedTagIds, tag.id));
  };

  const { isEditor } = usePermissions();

  return (
    <div
      className={cn('flex-1 flex flex-col gap-2', {
        'opacity-80': translucent,
      })}
    >
      <div className='flex flex-row justify-between'>
        <span>{group.name}</span>
        {!isEditor && (
          <div className='flex flex-row gap-1'>
            <Button
              onClick={removeTagGroupHandler}
              variant='link'
              className='py-0.5 px-1 h-auto text-destructive'
            >
              <FaTrash />
            </Button>
            <Button
              variant='link'
              className='py-0.5 px-1 h-auto'
              onClick={openUpdateTagGroupModal}
            >
              <FaEdit />
            </Button>
          </div>
        )}
      </div>
      <DroppableTagsArea id={group.id}>
        <Tag.Group>
          {group.tags.map((tag) => (
            <Tag
              draggable
              key={tag.id}
              {...tag}
              selected={selectedTagIds.includes(tag.id)}
              onUpdate={isEditor ? undefined : updateTagHandler}
              onRemove={isEditor ? undefined : removeTagHandler}
              onSelect={toggleTagHandler}
            />
          ))}
        </Tag.Group>
      </DroppableTagsArea>
    </div>
  );
};

export const TagsGalleryGroupSortable = ({
  group,
  translucent,
}: TagsGalleryGroupProps) => {
  const { attributes, listeners, setNodeRef, transform, transition } =
    useSortable({ id: group.id });
  const { isEditor } = usePermissions();

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  };
  return (
    <div
      className='mt-1 flex flex-row gap-2 items-center'
      ref={setNodeRef}
      style={style}
    >
      <TagsGalleryGroup group={group} translucent={translucent} />
      {!isEditor && (
        <div {...attributes} {...listeners}>
          <FaEllipsisVertical />
        </div>
      )}
    </div>
  );
};
