import React from 'react';
import { useTranslation } from 'react-i18next';
import { FaMarker, FaUpload } from 'react-icons/fa6';
import { Link } from '@tanstack/react-router';
import { useQuery, useQueryClient } from '@tanstack/react-query';

import { api } from '@admin/features/api';
import { PageTemplate } from '@admin/features/layout/components/PageTemplate/PageTemplate';
import { FileItem } from '@admin/features/assets/components/Items/Assets/File';
import { Button } from '@admin/features/theme/components/Button/Button';
import { DirectoryItem } from '@admin/features/assets/components/Items/Assets/Directory';
import { Loader } from '@admin/features/theme/components/Loader/Loader';
import { EmptyListAlert } from '@admin/features/layout/components/PageTemplate/EmptyListAlert';
import { FileResponseDto } from '@admin/features/api/generated';
import { isFile, isFolder } from '@admin/features/assets/utils/filter';
import { SingleFolderBreadcrumbs } from '../Breadcrumbs/SingleFolderBreadcrumbs';
import { usePermissions } from '@admin/features/auth/usePermissions';

type SingleFolderProps = {
  folderId: string;
  folderInfo: FileResponseDto;
};

export const SingleFolder: React.FC<SingleFolderProps> = ({
  folderId,
  folderInfo,
}) => {
  const { t } = useTranslation('assets');

  const queryKey = ['single-folder', folderId];
  const { data, isSuccess, isLoading, isError } = useQuery({
    queryKey,
    queryFn: () => api.directoryControllerScanDir(folderId),
  });

  const { data: parentFolderData, isLoading: parentFolderDataLoading } =
    useQuery({
      queryKey: ['parent-folder', folderInfo.parentDirectoryId],
      queryFn: () => api.fileControllerGetFile(folderInfo.parentDirectoryId!),
      enabled: folderInfo.parentDirectoryId !== 'ROOT',
    });

  const { isEditor } = usePermissions();

  return (
    <PageTemplate
      title={folderInfo.name}
      breadcrumbs={
        <SingleFolderBreadcrumbs
          folderInfo={folderInfo}
          isLoading={parentFolderDataLoading}
          parentFolderData={parentFolderData?.data}
        />
      }
    >
      <div className='flex flex-row-reverse gap-2'>
        {!isEditor && (
          <Link to='/assets/folder/$folderId/create' params={{ folderId }}>
            <Button className='flex gap-2 items-center'>
              <FaMarker />
              {t('folders.all.create.btn')}
            </Button>
          </Link>
        )}
        <Link to='/assets/folder/$folderId/upload' params={{ folderId }}>
          <Button className='flex gap-2 items-center'>
            <FaUpload />
            {t('folders.single.upload.btn')}
          </Button>
        </Link>
      </div>
      <div className='grid w-full gap-2 grid-cols-1 md:grid-cols-2 lg:grid-cols-4 xl:grid-cols-5 2xl:grid-cols-7'>
        {isLoading && (
          <div className='col-span-full'>
            <Loader className='col-span-full' />
          </div>
        )}
        {isError && (
          <h1 className='text-red-600 text-center col-span-full'>
            {t('folders.fetch.error')}
          </h1>
        )}
        {isSuccess && data.data.length > 0 ? (
          <div className='w-full col-span-full space-y-2'>
            <div className='col-span-full grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 xl:grid-cols-5 2xl:grid-cols-7 gap-2'>
              {data.data.filter(isFolder).map((folder) => (
                <DirectoryItem
                  key={folder.id}
                  queryKey={queryKey}
                  {...folder}
                />
              ))}
            </div>
            <div className='col-span-full grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 xl:grid-cols-5 2xl:grid-cols-7 gap-2'>
              {data.data.filter(isFile).map((file) => (
                <FileItem
                  key={file.id}
                  queryKey={queryKey}
                  folderId={folderId}
                  {...file}
                />
              ))}
            </div>
          </div>
        ) : (
          <div className='col-span-full'>
            <EmptyListAlert />
          </div>
        )}
      </div>
    </PageTemplate>
  );
};
