import { Modal } from '@admin/features/theme/components/Modals/Modal';
import React, { useEffect, useState } from 'react';
import { Button } from '@admin/features/theme/components/Button/Button';
import {
  closestCenter,
  DndContext,
  DragEndEvent,
  DragOverlay,
  DragStartEvent,
  KeyboardSensor,
  PointerSensor,
  useSensor,
  useSensors,
} from '@dnd-kit/core';
import {
  TagGroupResponseDto,
  TagGroupWithTagsDto,
  TagResponseDto,
  TagVisibility,
} from '@admin/features/api/generated';
import { useMutation, useQuery } from '@tanstack/react-query';
import { api } from '@admin/features/api';
import { Loader } from '@admin/features/theme/components/Loader/Loader';
import { Tag } from '@admin/features/blogs/components/Tag/Tag';
import { createNotification } from '@admin/features/layout/utils/createNotification';
import { TagGroupFormModal } from '@admin/features/blogs/BlogEditor/modals/TagGroupFormModal/TagGroupFormModal';
import { CreateTagModal } from '../CreateTagModal/CreateTagModal';
import { useModal } from '@admin/features/layout/modals/useModal';
import { useBlogsTranslation } from '@admin/features/blogs/locales/useBlogsTranslation';
import { TagsGalleryGroups } from './TagsGalleryGroups';
import { arrayMove } from '@dnd-kit/sortable';
import { useHandledMutation } from '@admin/hooks/useHandledMutation';
import { usePermissions } from '@admin/features/auth/usePermissions';

export const TagsGalleryModal: React.FC = () => {
  const { t } = useBlogsTranslation();
  const { openModal, closeModal, openPreviousModal } = useModal();
  const [groups, setGroups] = useState<TagGroupWithTagsDto[] | undefined>();
  const [activeItem, setActiveItem] = useState<
    { type: 'tag'; item: TagResponseDto } | { type: 'group'; id: string } | null
  >(null);

  const sensors = useSensors(
    useSensor(PointerSensor, {
      activationConstraint: {
        distance: 8,
      },
    }),
    useSensor(KeyboardSensor)
  );

  const { data, isFetching, isFetched } = useQuery({
    queryFn: () => api.tagControllerFindAllWithGroups(),
    queryKey: ['tagControllerFindAllWithGroups'],
    staleTime: 0,
  });

  const { mutate: mutateChangeParent } = useMutation({
    mutationFn: (values: TagResponseDto) => {
      return api.tagControllerUpdate(values.id, {
        color: values.color,
        slug: values.slug,
        tagGroupId: values.tagGroup?.id,
        translations: values.translations,
        visibility: values.visibility as TagVisibility,
      });
    },
    onSuccess: (_, data) => {
      createNotification({
        variant: 'success',
        content: t('editor.form.create.success'),
      });
      setGroups((groups) => {
        return groups?.map((group) => {
          if (group.id === data.tagGroup?.id) {
            if (group.tags.find(({ id }) => data.id === id)) {
              return { ...group };
            }

            return {
              ...group,
              tags: [...group.tags, data],
            };
          }

          return {
            ...group,
            tags: group.tags.filter((tag) => tag.id !== data.id),
          };
        });
      });
    },
    onError: (error) => {
      createNotification({
        variant: 'error',
        content: error.message,
      });
    },
  });

  const { mutate: mutateSort } = useHandledMutation({
    mutationFn: (ids: string[]) => api.tagGroupControllerUpdateSort({ ids }),
  });

  const onDragStart = (e: DragStartEvent): void => {
    const g = groups?.find((g) => g.id === e.active.id);
    setActiveItem(
      g
        ? {
            type: 'group',
            id: g.id,
          }
        : {
            type: 'tag',
            item: e.active.data.current as TagResponseDto,
          }
    );
  };

  const onDragEnd = (e: DragEndEvent): void => {
    setActiveItem(null);
    const { active, over } = e;
    const group = groups?.find((g) => g.id === active.id);
    if (group) {
      if (groups) {
        const oldIndex = groups.findIndex((g) => g.id === active.id);
        const newIndex = groups.findIndex((g) => g.id === over?.id);

        const items = arrayMove(groups, oldIndex, newIndex);
        setGroups(items);
        mutateSort(items.map((g) => g.id));
      }
      return;
    }
    if (
      !over ||
      active.id === over.id ||
      over.data.current?.parentId === active.id
    ) {
      return;
    }

    const activeTag = active.data.current as TagResponseDto;
    const previousGroup = groups?.find(({ tags }) =>
      tags.find(({ id }) => activeTag.id === id)
    );

    if (over.id.toString() === previousGroup?.id) {
      return;
    }

    const nextGroup = groups?.find(({ id }) => id === over.id);

    if (nextGroup) {
      mutateChangeParent({ ...activeTag, tagGroup: nextGroup });
    }
  };

  const openCreateTagGroupModal = (): void => {
    openModal(<TagGroupFormModal />);
  };

  const openCreateTagModal = (): void => {
    openModal(<CreateTagModal />);
  };

  useEffect(() => {
    if (isFetched && data) {
      setGroups(data.data);
    }
  }, [data, isFetched]);

  const { isEditor } = usePermissions();

  return (
    <Modal
      isOpen
      title={t('editor.modals.tags-gallery.title')}
      onPrevious={openPreviousModal}
      onConfirm={closeModal}
      onClose={closeModal}
      closeOnOutsideClick
      size='large'
    >
      <div className='flex flex-col gap-4'>
        {!isEditor && (
          <div className='flex gap-4 flex-wrap'>
            <Button onClick={openCreateTagGroupModal}>
              {t('editor.modals.tags-gallery.buttons.create-tag-group')}
            </Button>
            <Button onClick={openCreateTagModal}>
              {t('editor.modals.tags-gallery.buttons.create-tag')}
            </Button>
          </div>
        )}
        {isFetching ? (
          <Loader />
        ) : (
          <DndContext
            sensors={sensors}
            collisionDetection={closestCenter}
            onDragStart={onDragStart}
            onDragEnd={onDragEnd}
          >
            <TagsGalleryGroups
              groups={groups ?? []}
              activeId={(activeItem?.type === 'group' && activeItem.id) || null}
            />
            {activeItem && activeItem.type === 'tag' && (
              <DragOverlay>
                <Tag key={activeItem.item.id} {...activeItem.item} />
              </DragOverlay>
            )}
          </DndContext>
        )}
      </div>
    </Modal>
  );
};
