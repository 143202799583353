import { queryClient } from '@admin/features/api';
import { AuthContext } from '@admin/features/auth/context/AuthProvider/AuthProvider';
import { QueryClientProvider } from '@tanstack/react-query';
import { Outlet, createRootRouteWithContext } from '@tanstack/react-router';
import '@order-mage/react-ui/Button.css';

export type RouterContext = {
  auth: AuthContext;
};

export const Route = createRootRouteWithContext<RouterContext>()({
  component: () => (
    <QueryClientProvider client={queryClient}>
      <Outlet />
    </QueryClientProvider>
  ),
});
