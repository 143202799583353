import {
  BlogGroupResponseDto,
  BlogLevelAccessDto,
  BlogLevelAccessRole,
} from '@admin/features/api/generated';
import {
  usePermissions,
  UsePermissionsReturn,
} from '@admin/features/auth/usePermissions';
import { useMemo } from 'react';

type UseBlogPermissions = (blogGroup: BlogGroupResponseDto) => {
  canEdit: boolean;
  canRemove: boolean;
  blogGroupAccess?: BlogLevelAccessDto;
} & UsePermissionsReturn;

export const useBlogPermissions: UseBlogPermissions = (blogGroup) => {
  const { isEditor, isChief, isAdmin, permissions } = usePermissions();

  const blogGroupAccess = useMemo(
    () =>
      permissions?.blogLevelAccess.find(
        (blogGroupAccess) => blogGroupAccess.blogGroup.id === blogGroup.id
      ),
    [blogGroup.id, permissions?.blogLevelAccess]
  );
  const editOnly = useMemo(
    () =>
      blogGroupAccess?.blogLevelAccessRole ===
      BlogLevelAccessRole.BlogLevelEditor,
    [blogGroupAccess?.blogLevelAccessRole]
  );

  const fullAccessOnly = useMemo(
    () =>
      blogGroupAccess?.blogLevelAccessRole ===
      BlogLevelAccessRole.BlogLevelFullAccess,
    [blogGroupAccess?.blogLevelAccessRole]
  );

  const canEdit = useMemo(
    () => isAdmin || (isChief && (editOnly || fullAccessOnly)),
    [editOnly, fullAccessOnly, isAdmin, isChief]
  );

  const canRemove = useMemo(
    () => isAdmin || ((isChief || isEditor) && fullAccessOnly),
    [fullAccessOnly, isAdmin, isChief, isEditor]
  );

  return {
    canEdit,
    canRemove,
    isEditor,
    isChief,
    isAdmin,
    permissions,
    user: permissions,
    blogGroupAccess,
  };
};
