import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FaPen, FaTrash } from 'react-icons/fa6';
import { QueryKey, useQuery, useQueryClient } from '@tanstack/react-query';
import { Link } from '@tanstack/react-router';

import { cn } from '@admin/utils/cn';
import { api } from '@admin/features/api';
import {
  FileResponseDto,
  UpdateDirectoryDto,
} from '@admin/features/api/generated';
import { RemoveButton } from '@admin/features/layout/components/RemoveButton/RemoveButton';
import { useHandledMutation } from '@admin/hooks/useHandledMutation';
import { pathResolve } from '@admin/features/assets/utils/path';
import { Button } from '@admin/features/theme/components/Button/Button';
import { Modal } from '@admin/features/theme/components/Modals/Modal';
import { useNotificationDispatch } from '@admin/features/layout/hooks/useNotificationDispatch';
import { InputField } from '@admin/features/form/components/InputField';
import { FormProvider } from 'react-hook-form';
import { useForm } from '@admin/hooks/useForm';
import * as yup from 'yup';
import { ChangeFolderModal } from '../../ChangeFolderModal/ChangeFolderModal';
import { invalidateFolders } from '@admin/features/assets/utils/invalidation';
import { usePermissions } from '@admin/features/auth/usePermissions';

const schema = yup.object({
  name: yup.string().required().ensure(),
  path: yup.string().required().ensure(),
});

type DirectoryItemProps = FileResponseDto & {
  queryKey: QueryKey;
  className?: string;
};

export const DirectoryItem: React.FC<DirectoryItemProps> = ({
  queryKey,
  className,
  ...directoryData
}) => {
  const { id, name, path } = directoryData;
  const { t } = useTranslation('assets');
  const { isEditor } = usePermissions();
  const queryClient = useQueryClient();
  const createNotification = useNotificationDispatch();
  const [editModal, setEditModal] = useState(false);
  const [isPathChanged, setIsPathChanged] = useState(false);

  const { mutate: deleteFolder } = useHandledMutation({
    mutationFn: (id: string) => api.directoryControllerDeleteDirectory(id),
    onSuccess: async () => {
      createNotification({
        content: t('folders.folder.delete.success.alert', { folderName: name }),
        variant: 'success',
      });
      await queryClient.invalidateQueries({
        queryKey,
      });
    },
  });

  const {
    mutate: changeFolder,
    isPending: renamingIsPending,
    isSuccess: renameSuccess,
  } = useHandledMutation({
    mutationFn: ({ id, data }: { id: string; data: UpdateDirectoryDto }) =>
      api.directoryControllerUpdateDirectory(id, data),
    onSuccess: async (data) => {
      createNotification({
        content: t('upload.update.success.alert', {
          fileName: data.data.name,
        }),
        variant: 'success',
      });
      await invalidateFolders(
        queryClient,
        [path, data.data.path],
        [id, data.data.id]
      );
      await queryClient.invalidateQueries({
        queryKey: ['single-folder'],
      });
    },
  });

  const { data: blogData } = useQuery({
    queryKey: ['used-in-blogs', id],
    queryFn: () => api.blogFilesControllerGetFileBlogs(id),
  });

  const methods = useForm({
    schema,
    defaultValues: schema.cast({ name, path }),
  });

  const handleDelete = (): void => {
    deleteFolder(id);
  };

  const handleEdit = (): void => {
    setEditModal(true);
  };

  const handleEditModalSave = methods.handleSubmit(
    (values: { name: string; path: string }): void => {
      changeFolder({ id, data: { name: values.name, path: values.path } });
    }
  );

  useEffect(() => {
    if (renameSuccess) {
      setEditModal(false);
    }
  }, [renameSuccess]);

  const handleEditModalClose = (): void => {
    setEditModal(false);
  };

  const handleFolderSelect = (folder: FileResponseDto): void => {
    setIsPathChanged(path !== folder.fullPath);
    methods.setValue('path', folder.fullPath);
  };

  return (
    <div
      className={cn(
        'border col-span-1 rounded-lg w-full p-4 cursor-pointer truncate transition-all hover:border-blue-600',
        className
      )}
    >
      <Modal
        isOpen={editModal}
        title={t('folders.modal.edit.title', { folderName: name })}
        onClose={handleEditModalClose}
        onConfirm={handleEditModalSave}
        loading={renamingIsPending}
      >
        <div className='flex flex-col items-start space-y-2'>
          <FormProvider {...methods}>
            {!isEditor && (
              <div className='flex gap-2 w-full'>
                <InputField
                  name='path'
                  className={cn(
                    'text-gray-400',
                    isPathChanged && 'text-blue-600 text-opacity-60'
                  )}
                  readOnly
                />
                <ChangeFolderModal
                  as={Button}
                  onFolderSelect={handleFolderSelect}
                >
                  {t('change.folder.btn')}
                </ChangeFolderModal>
              </div>
            )}
            <InputField name='name' />
          </FormProvider>
        </div>
      </Modal>
      <Link to={id ? `/assets/folder/${id}` : '/assets'}>
        <span className='text-xs text-gray-400'>{pathResolve(path, name)}</span>
        <div className='flex flex-col'>
          <span className='font-semibold truncate'>{name}</span>
        </div>
      </Link>
      <div className='flex flex-row justify-end'>
        <Button
          className='items-end'
          variant={'link'}
          size={'icon'}
          onClick={handleEdit}
        >
          <FaPen />
        </Button>
        {!isEditor && (
          <RemoveButton
            className='items-end text-red-700'
            variant={'link'}
            size={'icon'}
            buttonLabel={<FaTrash />}
            onConfirm={handleDelete}
          >
            <div className='flex flex-col gap-2'>
              <span>{t('folders.folder.remove.confirm')}</span>
              {blogData?.data.length === 0 ? (
                <span>{t('folders.removal.folder.list.text.unused')}</span>
              ) : (
                <div className='flex flex-col gap-2'>
                  <span
                    className={cn(
                      (blogData?.data.length ?? 0) > 0 && 'text-red-600'
                    )}
                  >
                    {t('folders.removal.folder.list.text', {
                      blogCount: blogData?.data.length ?? '...',
                    })}
                  </span>
                  <ul className='marker:text-black list-disc'>
                    {blogData?.data.map((blog) => (
                      <Link
                        to={`/blogs/${blog.id}/update-blog`}
                        target='_blank'
                        key={blog.id}
                        className='block hover:underline underline-offset-1 text-black ml-4'
                      >
                        {blog.name}
                      </Link>
                    ))}
                  </ul>
                </div>
              )}
            </div>
          </RemoveButton>
        )}
      </div>
    </div>
  );
};
