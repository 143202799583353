import React from 'react';
import { FileResponseDto } from '@admin/features/api/generated';
import { PageTemplate } from '@admin/features/layout/components/PageTemplate/PageTemplate';
import { useRouter } from '@tanstack/react-router';
import { CreateFolderBreadcrumbs } from '../Breadcrumbs/CreateFolderBreadcrumbs';
import { CreateFolderForm } from '@admin/features/assets/components/CreateFolderForm/CreateFolderForm';
import { useTranslation } from 'react-i18next';

type CreateFolderProps = {
  folderId: string;
  folderInfo: FileResponseDto;
  folderData: FileResponseDto[];
};

export const CreateFolder: React.FC<CreateFolderProps> = ({
  folderId,
  folderInfo,
  folderData,
}) => {
  const router = useRouter();
  const { t } = useTranslation('assets');
  const handleDirectoryCreated = () => {
    router.history.back();
  };

  return (
    <PageTemplate
      title={t('folders.create.title')}
      breadcrumbs={<CreateFolderBreadcrumbs folderInfo={folderInfo} />}
    >
      <CreateFolderForm
        onDirectoryCreated={handleDirectoryCreated}
        folderInfo={folderInfo}
        folderId={folderId}
        folderData={folderData}
      />
    </PageTemplate>
  );
};
