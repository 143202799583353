import { ReactNode } from 'react';
import { useFormattedDate } from '@admin/hooks/useFormattedDate';
import { useBlogsTranslation } from '@admin/features/blogs/locales/useBlogsTranslation';

type BlogUpdatedDateProps = {
  updatedAt: string;
  updatedBy: string;
};

export const BlogUpdatedDateBadge = ({
  updatedAt,
  updatedBy,
}: BlogUpdatedDateProps): ReactNode => {
  const { t } = useBlogsTranslation();
  const updatedDate = useFormattedDate(updatedAt);

  return (
    <span className='text-xs font-medium me-2 px-2.5 py-0.5 rounded border dark:bg-gray-700 text-gray-800 dark:text-gray-400 border-gray-500'>
      {t('badge.updated', { date: updatedDate, name: updatedBy })}
    </span>
  );
};
