import { FC, ReactNode } from 'react';
import { cn } from '@admin/utils/cn';

export type BlogPostListItemWrapperProps = {
  children?: ReactNode;
  className?: string;
  disableGroupColumn?: boolean;
};

export const BlogPostListItemWrapper: FC<BlogPostListItemWrapperProps> = ({
  children,
  className,
  disableGroupColumn,
}) => {
  return (
    <div
      className={cn(className, 'grid', {
        'grid-cols-[3fr_1fr_1fr_2fr]': !disableGroupColumn,
        'grid-cols-[3fr_1fr_1fr]': disableGroupColumn,
      })}
    >
      {children}
    </div>
  );
};
