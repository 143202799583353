import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  FaArrowUpRightFromSquare,
  FaCopy,
  FaPen,
  FaShare,
  FaTrash,
} from 'react-icons/fa6';
import { QueryKey, useQuery, useQueryClient } from '@tanstack/react-query';

import { cn } from '@admin/utils/cn';
import { api } from '@admin/features/api';
import { Badge } from '@admin/features/theme/components/Badge/Badge';
import { Button } from '@admin/features/theme/components/Button/Button';
import { Modal } from '@admin/features/theme/components/Modals/Modal';
import { FileResponseDto, Language } from '@admin/features/api/generated';
import { Input } from '@admin/features/theme/components/Input/Input';
import { RemoveButton } from '@admin/features/layout/components/RemoveButton/RemoveButton';
import { useHandledMutation } from '@admin/hooks/useHandledMutation';
import { useNotificationDispatch } from '@admin/features/layout/hooks/useNotificationDispatch';
import { EmptyListAlert } from '@admin/features/layout/components/PageTemplate/EmptyListAlert';
import { Link } from '@tanstack/react-router';
import { usePermissions } from '@admin/features/auth/usePermissions';
import env from '@admin/env';

type FileItemProps = FileResponseDto & {
  queryKey: QueryKey;
  className?: string;
  folderId?: string;
};

export const FileItem: React.FC<FileItemProps> = ({
  id,
  name,
  path,
  type,
  queryKey,
  translations,
  className,
  folderId,
}) => {
  const { t } = useTranslation('assets');
  const { isEditor } = usePermissions();
  const [modalsState, setModalsState] = useState({
    preview: false,
    share: false,
  });
  const [selectedLanguage, setSelectedLanguage] = useState(
    translations[0]?.language ?? Language.Cs
  );
  const translation = translations.find((t) => t.language === selectedLanguage);
  const queryClient = useQueryClient();
  const createNotification = useNotificationDispatch();

  const { mutate } = useHandledMutation({
    mutationFn: (id: string) => api.fileControllerDeleteFile(id),
    onSuccess: async () => {
      createNotification({
        content: t('folders.file.delete.success.alert', { fileName: name }),
        variant: 'success',
      });
      await queryClient.invalidateQueries({
        queryKey,
      });
    },
  });

  const { data: blogData } = useQuery({
    queryKey: ['used-in-blogs', id],
    queryFn: () => api.blogFilesControllerGetFileBlogs(id),
  });

  const openModal = <T extends HTMLElement>(e: React.MouseEvent<T>): void => {
    const { modal } = e.currentTarget.dataset;
    if (!modal) {
      return;
    }

    setModalsState((prev) => ({ ...prev, [modal]: true }));
  };

  const closeModal = (modalName: string): void => {
    setModalsState((prev) => ({ ...prev, [modalName]: false }));
  };

  const handleShare = (): void => {
    closeModal('share');
  };

  const handleDelete = (): void => {
    mutate(id);
  };

  const handleCopyURL = (publicUrl: string) => (): Promise<void> =>
    navigator.clipboard.writeText(publicUrl);

  const [fileName] = name.split('.');

  return (
    <>
      <Modal
        isOpen={modalsState.preview}
        onClose={() => {
          closeModal('preview');
        }}
        title={t('folders.single.preview.modal.title')}
      >
        <div className='flex flex-col gap-2'>
          {translations.map(({ language }) => (
            <Button
              size='sm'
              key={language}
              variant={language === selectedLanguage ? 'default' : 'ghost'}
              onClick={() => {
                setSelectedLanguage(language);
              }}
            >
              {language}
            </Button>
          ))}
          {translation ? (
            <div key={translation.id} className='col-span-1 lg:col-span-6'>
              {/^image\//i.test(type) ? (
                <img src={translation.publicUrl} alt={translation.language} />
              ) : (
                <video src={translation.publicUrl} autoPlay={false} controls />
              )}
            </div>
          ) : (
            <EmptyListAlert />
          )}
        </div>
      </Modal>
      <Modal
        isOpen={modalsState.share}
        onClose={() => {
          closeModal('share');
        }}
        onConfirm={handleShare}
        title={t('folders.single.share.modal.title')}
      >
        {translations.map((translation, index) => {
          const downloadUrl = translation.downloadPathname
            ? new URL(translation.downloadPathname, env.apiUrl).toString()
            : '';
          return (
            <div key={index} className='space-y-2'>
              <h1 className='font-bold uppercase'>{translation.language}:</h1>
              <div className='flex gap-2'>
                <Input
                  value={downloadUrl || t('folders.file.share.url.missing')}
                  readOnly
                  disabled
                />
                <Button
                  size={'icon'}
                  value={downloadUrl}
                  onClick={handleCopyURL(downloadUrl)}
                >
                  <FaCopy />
                </Button>
                <Button
                  size={'icon'}
                  value={downloadUrl}
                  variant={'secondary'}
                  onClick={() => window.open(downloadUrl, '_blank')}
                >
                  <FaArrowUpRightFromSquare />
                </Button>
              </div>
            </div>
          );
        })}
      </Modal>
      <div
        className={cn(
          'border col-span-1 rounded-lg w-full cursor-pointer truncate transition-all hover:border-blue-600',
          className
        )}
      >
        <div className='flex flex-col gap-2 h-full'>
          <div className='flex flex-col items-center gap-2'>
            <div
              className='w-full h-28 cursor-pointer group relative hover:cursor-zoom-in'
              data-modal='preview'
              onClick={openModal}
            >
              <span className='absolute opacity-0 top-1 right-1 bg-white border transition-opacity border-black rounded-xl p-1 group-hover:opacity-100 text-black text-xs uppercase'>
                {type}
              </span>
              {/^image\//i.test(type) ? (
                <img
                  src={translations[0].publicUrl}
                  className='rounded-t-lg w-full h-full object-cover'
                  alt={translations[0].language}
                  loading='lazy'
                />
              ) : (
                <video
                  src={translations[0].publicUrl}
                  className='rounded-t-lg w-full h-full object-cover'
                  autoPlay={false}
                  controls={false}
                />
              )}
            </div>
            <div className='flex flex-col w-full mt-4 px-4 gap-2'>
              <span className='text-gray-400 text-xs'>{path}</span>
              <span className='flex items-center gap-2 truncate'>
                <span className='gap-2 font-semibold truncate'>{fileName}</span>
              </span>
              <div className='flex flex-wrap gap-2 truncate'>
                {translations.map((translation) => (
                  <Badge key={translation.id} variant={'outline'}>
                    {translation.language}
                  </Badge>
                ))}
              </div>
            </div>
          </div>
          <div className='flex items-center justify-end pr-4 pb-4 gap-2'>
            <Button
              as={Link}
              to={'/assets/folder/$folderId/file/$fileId/upload'}
              params={{ folderId: folderId ?? 'root', fileId: id } as never}
              variant={'link'}
              size={'icon'}
            >
              <FaPen />
            </Button>
            <Button
              data-modal='share'
              onClick={openModal}
              variant={'link'}
              size={'icon'}
            >
              <FaShare />
            </Button>
            {!isEditor && (
              <RemoveButton
                buttonLabel={<FaTrash />}
                onConfirm={handleDelete}
                className='text-red-700 whitespace-pre-line'
                variant={'link'}
                size={'icon'}
              >
                <div className='flex flex-col gap-2'>
                  <span>{t('folders.file.remove.confirm')}</span>
                  {blogData?.data.length === 0 ? (
                    <span>{t('folders.removal.list.text.unused')}</span>
                  ) : (
                    <div className='flex flex-col gap-2'>
                      <span
                        className={cn(
                          (blogData?.data.length ?? 0) > 0 && 'text-red-600'
                        )}
                      >
                        {t('folders.removal.list.text', {
                          blogCount: blogData?.data.length ?? '...',
                        })}
                      </span>
                      <ul className='marker:text-black list-disc'>
                        {blogData?.data.map((blog) => (
                          <Link
                            to={`/blogs/${blog.id}/update-blog`}
                            target='_blank'
                            key={blog.id}
                            className='block hover:underline underline-offset-1 text-black ml-4'
                          >
                            {blog.name}
                          </Link>
                        ))}
                      </ul>
                    </div>
                  )}
                </div>
              </RemoveButton>
            )}
          </div>
        </div>
      </div>
    </>
  );
};
