import { FC } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';
import { InferType } from 'yup';

import { api } from '@admin/features/api';
import { InputField } from '@admin/features/form/components/InputField';
import { Button } from '@admin/features/theme/components/Button/Button';
import {
  Card,
  CardContent,
  CardDescription,
  CardHeader,
  CardTitle,
} from '@admin/features/theme/components/Card/Card';
import { useYupValidationResolver } from '@admin/hooks/useYupValidationResolver';
import { storage } from '@admin/storage';
import { authActions } from '@admin/features/auth/state/authSlice';
import { useDispatch } from '@admin/store/store';
import { useMutation } from '@tanstack/react-query';
import { Link } from '@tanstack/react-router';

const schema = yup.object({
  email: yup.string().required().email().ensure(),
  password: yup.string().required().ensure(),
});
type FormValues = InferType<typeof schema>;

export const Login: FC = () => {
  const { t } = useTranslation('auth');
  const dispatch = useDispatch();
  const resolver = useYupValidationResolver(schema);
  const methods = useForm({
    resolver,
    defaultValues: schema.cast({}),
  });
  const { handleSubmit } = methods;
  const { isPending, mutate, isError } = useMutation({
    mutationFn: (values: FormValues) => api.authControllerSignIn(values),
    onSuccess: (response) => {
      dispatch(authActions.setIsAuthenticated(Boolean(response.data)));
      storage.write('auth', response.data);
      // eslint-disable-next-line no-warning-comments
      // todo: resolve this because tanstack doesn't want to redirect (🤦‍♂️)
      window.location.reload();
    },
  });
  const submitCallback = handleSubmit((values: FormValues) => {
    mutate(values);
  });
  return (
    <FormProvider {...methods}>
      <form
        onSubmit={submitCallback}
        className='flex h-screen justify-center items-center'
      >
        <Card className='max-w-sm w-full'>
          <CardHeader>
            <CardTitle className='text-2xl'>{t('login.title')}</CardTitle>
            {isError && (
              <CardDescription className='text-destructive'>
                {t('login.form.wrongCredentialsError')}
              </CardDescription>
            )}
          </CardHeader>
          <CardContent>
            <div className='grid gap-4'>
              <div className='grid gap-2'>
                <InputField
                  name='email'
                  type='email'
                  label={t('login.form.email.label')}
                  placeholder={t('login.form.email.placeholder')}
                />
              </div>
              <div className='grid gap-2'>
                <InputField
                  type='password'
                  name='password'
                  label={t('login.form.password.label')}
                />
              </div>
              <Button type='submit' className='w-full' loading={isPending}>
                {t('login.form.submit')}
              </Button>
              <Link to='/forgot-password' className='text-center text-sm'>
                {t('login.form.forgotPassword')}
              </Link>
            </div>
          </CardContent>
        </Card>
      </form>
    </FormProvider>
  );
};
