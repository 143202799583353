import React, { useState } from 'react';
import { FileRejection } from 'react-dropzone';
import { ControllerRenderProps, FormProvider } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';
import { InferType } from 'yup';
import { AxiosResponse } from 'axios';
import { useRouter } from '@tanstack/react-router';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import { api } from '@admin/features/api';
import {
  CreateFileDto,
  FileMetadataDto,
  FileResponseDto,
  FileVisibility,
  Language,
  TagResponseDto,
  UpdateFileDto,
} from '@admin/features/api/generated';
import { Tab, TabGroup, TabList, TabPanel, TabPanels } from '@headlessui/react';
import { Button } from '@admin/features/theme/components/Button/Button';
import { Input } from '@admin/features/theme/components/Input/Input';
import { Loader } from '@admin/features/theme/components/Loader/Loader';
import {
  RadioGroup,
  RadioGroupItem,
} from '@admin/features/theme/components/RadioGroup/RadioGroup';
import { Select } from '@admin/features/theme/components/Select/Select';
import { UploadDropzone } from '@admin/features/theme/components/UploadDropzone/UploadDropzone';
import { useNotificationDispatch } from '@admin/features/layout/hooks/useNotificationDispatch';
import { useHandledMutation } from '@admin/hooks/useHandledMutation';
import { useForm } from '@admin/hooks/useForm';
import { FormField } from '@admin/features/theme/components/Form/Form';
import { renameFile } from '@admin/features/assets/utils/fileRename';
import { ChangeFolderModal } from '@admin/features/assets/components/ChangeFolderModal/ChangeFolderModal';
import { invalidateFolders } from '@admin/features/assets/utils/invalidation';
import { cn } from '@admin/utils/cn';
import { useModal } from '@admin/features/layout/modals/useModal';
import TagsSearchModal from '@admin/features/blogs/BlogEditor/modals/TagsSearchModal/TagsSearchModal';
import { Tag } from '@admin/features/blogs/components/Tag/Tag';
import { FaPen } from 'react-icons/fa6';
import { splitName } from '@admin/features/assets/utils/fileName';

type UploadFileProps = {
  directoryId?: string;
  path: string;
  existingFile?: FileResponseDto;
  folderData: FileResponseDto[];
  onUpload?(file: AxiosResponse<FileResponseDto>): void;
};

const schema = yup
  .object({
    name: yup.string().ensure(),
    path: yup.string().required().ensure(),
    visibility: yup
      .string()
      .required()
      .oneOf(Object.values(FileVisibility))
      .default(FileVisibility.Public),
    tagIds: yup.array(yup.string().required().uuid()).required().default([]),
    translations: yup
      .array(
        yup
          .object({
            language: yup.string().required().oneOf(Object.values(Language)),
            file: yup.mixed().nullable(),
          })
          .required()
      )
      .default([]),
  })
  .noUnknown();

type FormValues = InferType<typeof schema>;

export const UploadFile: React.FC<UploadFileProps> = ({
  directoryId,
  path,
  existingFile,
  folderData,
  onUpload,
}) => {
  const { t } = useTranslation('assets');
  const router = useRouter();
  const queryClient = useQueryClient();
  const createNotification = useNotificationDispatch();
  const [selectedMutation, setSelectedMutation] = useState(
    existingFile ? existingFile.translations[0].language : Language.En
  );
  const [uploadProgress, setUploadProgress] = useState<null | number>(null);
  const [pickedLanguage, setPickedLanguage] = useState<null | Language>(null);
  const [shouldRenameOnUpload, setShouldRenameOnUpload] =
    useState<boolean>(!existingFile);
  const [isFileAlreadyExisting, setIsFileAlreadyExisting] =
    useState<boolean>(false);
  const [isFilePathChanged, setIsFilePathChanged] = useState<boolean>(false);

  const [availableMutations, setAvailableMutations] = useState(
    existingFile
      ? existingFile.translations.map((t) => t.language)
      : [Language.En]
  );

  const { Modal, openModal } = useModal();
  const methods = useForm<FormValues>({
    schema,
    defaultValues: schema.cast(
      existingFile
        ? {
            ...existingFile,
            tagIds: existingFile.tags.map((t) => t.id),
            translations: existingFile.translations.map((t) => ({
              ...t,
              file: t.publicUrl,
            })),
          }
        : {
            path,
            translations: [
              {
                file: null,
                language: selectedMutation,
              },
            ],
          }
    ),
  });
  const { watch, setValue } = methods;
  const tagIds = watch('tagIds');
  const files = watch('translations');

  const handleFileUploadProgressChange = (percentage: number | null) => {
    setUploadProgress(percentage);
  };

  const {
    mutate: mutateAddTranslation,
    mutateAsync: mutateAddTranslationAsync,
    isPending: isSavingTranslations,
  } = useHandledMutation({
    mutationFn: (data: {
      id: string;
      language: Language;
      body: UpdateFileDto;
    }) =>
      api.fileControllerUpsertFileTranslation(
        data.id,
        data.language,
        data.body,
        {
          onUploadProgress: (data) => {
            handleFileUploadProgressChange(data.progress ?? 0);
          },
        }
      ),
    onSuccess: async (response, rq) => {
      const idx = files.findIndex((f) => f.language === rq.language);
      const translation = response.data.translations.find(
        (t) => t.language === rq.language
      );
      if (idx >= 0 && translation) {
        setValue(`translations.${idx}.file`, translation.publicUrl);
      }
      if (response.data.parentDirectoryId) {
        await queryClient.invalidateQueries({
          queryKey: ['single-folder', response.data.parentDirectoryId],
        });
      }
      await invalidateFolders(queryClient, [path], [directoryId]);
      handleFileUploadProgressChange(null);
    },
  });

  const { mutate: mutateCreateFile, isPending: isSavingMainFile } =
    useHandledMutation({
      mutationFn: (data: CreateFileDto) =>
        api.fileControllerCreateNewFile(data, {
          onUploadProgress: (data) => {
            handleFileUploadProgressChange(data.progress ?? 0);
          },
        }),
      onSuccess: async (response) => {
        if (response.data.parentDirectoryId) {
          await queryClient.invalidateQueries({
            queryKey: ['single-folder', response.data.parentDirectoryId],
          });
        }
        createNotification({
          content: t('upload.alert.success.text', {
            fileName: response.data.name,
          }),
          variant: 'success',
        });
        const fileId = response.data.id;
        // refactor
        await Promise.all(
          files.slice(1).map(async (file) => {
            const { language } = file;
            if (!file.file) {
              return;
            }
            const data = {
              id: fileId,
              language,
              body: {
                file: file.file,
              },
            };
            return await mutateAddTranslationAsync(data);
          })
        );

        await invalidateFolders(queryClient, [path], [directoryId]);
        handleFileUploadProgressChange(null);
        if (!onUpload) {
          router.history.back();
          return;
        }
        onUpload(response);
      },
    });

  const { mutate: mutateDeleteTranslation, isPending: isDeletingTranslation } =
    useHandledMutation({
      mutationFn: ({ id, language }: { id: string; language: Language }) =>
        api.fileControllerDeleteFileTranslation(id, language),
      onSuccess: (_, { language }) => {
        const newMutations = availableMutations.filter((l) => l !== language);
        setAvailableMutations(newMutations);
        setSelectedMutation(newMutations[0]);
      },
    });

  const { mutate: mutateFileMetadata, isPending: isUpdatingMetadata } =
    useHandledMutation({
      mutationFn: ({
        id,
        metadata,
      }: {
        id: string;
        metadata: FileMetadataDto;
      }) => api.fileControllerUpdateFile(id, metadata),
      onSuccess: async (data) => {
        createNotification({
          content: t('upload.update.success.alert', {
            fileName: data.data.name,
          }),
          variant: 'success',
        });
        const paths = [path, data.data.path];
        const folderIds = [directoryId, data.data.id];
        await queryClient.invalidateQueries({ queryKey: ['all-folders'] });
        await queryClient.invalidateQueries({
          queryKey: ['single-folder', data.data.id],
        });
        await invalidateFolders(queryClient, paths, folderIds);
        router.history.back();
      },
    });

  const {
    data: allTags,
    isLoading,
    isError,
    isSuccess,
  } = useQuery({
    queryKey: ['all-tags'],
    queryFn: () => api.tagControllerFindAll(),
  });

  const saveMetadata = async () => {
    if (!existingFile) {
      return;
    }
    const values = methods.getValues();
    mutateFileMetadata({
      id: existingFile.id,
      metadata: {
        name: values.name,
        path: values.path,
        tagIds: values.tagIds,
        visibility: values.visibility,
      },
    });
    await queryClient.invalidateQueries({
      queryKey: ['single-file-info', existingFile.id],
    });
  };

  const selectedTags = allTags?.data.filter((tag: TagResponseDto) =>
    tagIds.includes(tag.id)
  );

  const handleMutationRemove = (): void => {
    if (availableMutations.length <= 1) {
      return;
    }
    if (existingFile) {
      mutateDeleteTranslation({
        id: existingFile.id,
        language: selectedMutation,
      });
    } else {
      const newMutations = availableMutations.filter(
        (l) => l !== selectedMutation
      );
      const translations = methods.getValues('translations');
      const searchedIndex = translations.findIndex(
        (translation) => translation.language === selectedMutation
      );
      const index = searchedIndex === -1 ? 0 : searchedIndex;

      const filteredTranslation = translations.filter((_, i) => i !== index);

      methods.setValue(`translations`, filteredTranslation);

      setAvailableMutations(newMutations);
      setSelectedMutation(newMutations[0]);
    }
  };

  const handleFileUpload =
    (field: ControllerRenderProps) =>
    (acceptedFiles: File[], declinedFiles: FileRejection[]): void => {
      if (declinedFiles.length !== 0) {
        createNotification({
          content: t('upload.alert.error.text', {
            fileName: declinedFiles[0].file.name,
            errorText: declinedFiles[0].errors[0].message,
          }),
          variant: 'error',
        });
      }
      if (shouldRenameOnUpload) {
        const ogFileName = acceptedFiles[0].name;
        const fileName = splitName(ogFileName);
        setValue('name', fileName?.[0] ?? ogFileName);
      }
      field.onChange({ target: { value: acceptedFiles[0] } });
    };

  const handleLanguageSelectChange = (
    e: React.ChangeEvent<HTMLSelectElement>
  ): void => {
    const { value } = e.target;
    if (!value) {
      return;
    }
    setPickedLanguage(value as Language);
  };

  const handleMutationAdd = (): void => {
    if (!pickedLanguage || availableMutations.includes(pickedLanguage)) {
      return;
    }
    setAvailableMutations((prev) => [...prev, pickedLanguage]);
    setSelectedMutation(pickedLanguage);
    setValue(`translations.${files.length}.language`, pickedLanguage);
  };

  const handleTabChange = (index: number): void => {
    setSelectedMutation(availableMutations[index]);
  };

  const handleNameChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    setIsFileAlreadyExisting(false);
    setShouldRenameOnUpload(e.target.value.length === 0);
    setValue('name', e.target.value);
  };

  const openSelectTagsModalHandler = (): void => {
    openModal(<TagsSearchModal />);
  };

  const checkNameValidity = (name: string): boolean => {
    const existingFiles = folderData
      .filter((file) => file.type !== 'directory')
      .map((item) => item.name.toLowerCase());

    const isValidName = !existingFiles.some(
      (fileName) => fileName === name.toLowerCase()
    );

    return isValidName;
  };

  const handleCreate = ({
    translations,
    name,
    ...values
  }: FormValues): void => {
    const files = translations.filter((t) => Boolean(t.file));
    if (!files.length) {
      return;
    }
    if (existingFile) {
      files.forEach((file) => {
        const { language } = file;
        if (!file.file || typeof file.file === 'string') {
          return;
        }
        const data = {
          id: existingFile.id,
          language,
          body: {
            file: file.file,
          },
        };
        mutateAddTranslation(data);
      });
    } else {
      const renamedFile = renameFile(translations[0].file as File, name);
      const isValidName = checkNameValidity(renamedFile.name);

      if (!isValidName) {
        setIsFileAlreadyExisting(true);
        // This should work ,but doesnt ???
        methods.setError('name', { message: t('upload.name.error') });
      } else {
        mutateCreateFile({
          ...values,
          // wtf
          tagIds: Array.isArray(values.tagIds)
            ? values.tagIds
            : [values.tagIds],
          file: renamedFile,
          language: translations[0].language,
        });
      }
    }
  };

  const handleFolderSelect = (folder: FileResponseDto): void => {
    if (!existingFile) {
      return;
    }
    setIsFilePathChanged(path !== folder.fullPath);
    setValue('path', folder.fullPath);
  };

  const descriptionSetting = {
    folderPath: watch('path'),
    interpolation: { escapeValue: false },
  };

  return (
    <FormProvider {...methods}>
      <Modal />
      <p className='text-xs text-gray-600'>
        {existingFile
          ? t('upload.update.folder.description', descriptionSetting)
          : t('upload.folder.description', descriptionSetting)}
      </p>
      <FormField
        name='visibility'
        render={({ field }) => (
          <div>
            <label>{t('upload.visibility.label')}</label>
            <RadioGroup
              className='w-64'
              data-preference='visibility'
              {...field}
            >
              <RadioGroupItem value='public'>
                {t('upload.visibility.public')}
              </RadioGroupItem>
              <RadioGroupItem value='internal'>
                {t('upload.visibility.private')}
              </RadioGroupItem>
            </RadioGroup>
          </div>
        )}
      />
      <div className='flex flex-col mb-2 items-start'>
        <p>
          <span>
            {t('upload.tags.label', {
              count: tagIds.length,
            })}
          </span>
          <Button
            variant={'link'}
            size={'icon'}
            onClick={openSelectTagsModalHandler}
          >
            <FaPen />
          </Button>
        </p>
        {isLoading && <Loader />}
        {isError && <h1 className='text-red-600'>{t('upload.tags.error')}</h1>}
        {isSuccess && allTags.data.length !== 0 && (
          <div>
            {selectedTags?.length === 0 ? (
              <p className='text-gray-500'>{t('upload.tags.no.selected')}</p>
            ) : (
              <Tag.Group>
                {selectedTags?.map((tag: TagResponseDto) => (
                  <Tag key={tag.id} {...tag} />
                ))}
              </Tag.Group>
            )}
          </div>
        )}
      </div>
      {existingFile && (
        <div className='flex flex-col gap-2 items-stretch md:flex-row w-full md:items-end'>
          <div className='flex-1 space-y-1'>
            <label htmlFor='name'>{t('upload.file.path.label')}</label>
            <Input
              type='text'
              {...methods.register('path')}
              className={cn(
                'text-gray-400',
                isFilePathChanged && 'text-blue-600 text-opacity-60'
              )}
            />
            {isFileAlreadyExisting && (
              <h1 className='text-red-600 text-sm'>{t('upload.name.error')}</h1>
            )}
          </div>

          <ChangeFolderModal as={Button} onFolderSelect={handleFolderSelect}>
            {t('change.folder.btn')}
          </ChangeFolderModal>
        </div>
      )}
      <div className='flex flex-col gap-4 items-stretch md:flex-row w-full md:items-end'>
        <div className='flex-1 space-y-1'>
          <label htmlFor='name'>{t('upload.file.name.label')}</label>
          <Input
            type='text'
            {...methods.register('name')}
            onChange={handleNameChange}
          />
          {isFileAlreadyExisting && (
            <h1 className='text-red-600 text-sm'>{t('upload.name.error')}</h1>
          )}
        </div>
        {existingFile && (
          <Button onClick={saveMetadata} className='min-w-48'>
            {t('upload.save.btn')}
          </Button>
        )}
      </div>
      <TabGroup
        className={'pb-4'}
        onChange={handleTabChange}
        selectedIndex={availableMutations.indexOf(selectedMutation)}
      >
        <div className='flex mb-4 mt-12 justify-between'>
          <TabList className='flex gap-2'>
            {availableMutations.map((mutation) => (
              <Tab
                as={Button}
                key={mutation}
                value={mutation}
                variant={selectedMutation === mutation ? 'default' : 'outline'}
              >
                {mutation}
              </Tab>
            ))}
          </TabList>
          <div className='flex gap-2'>
            {availableMutations.length > 1 && (
              <Button onClick={handleMutationRemove} variant='outline'>
                {t('upload.select.remove.btn')}
              </Button>
            )}
            <Select
              name='Language selector'
              defaultValue={''}
              className='w-48'
              onChange={handleLanguageSelectChange}
            >
              <option value=''>{t('upload.select.base.label')}</option>
              {Object.values(Language)
                .filter((value) => !availableMutations.includes(value))
                .map((value) => (
                  <option key={value} value={value}>
                    {value}
                  </option>
                ))}
            </Select>
            <Button onClick={handleMutationAdd}>
              {t('upload.select.add.btn')}
            </Button>
          </div>
        </div>
        <TabPanels>
          {availableMutations.map((mutation) => {
            const selectedFileIndex = files.findIndex(
              (file) => file.language === mutation
            );
            const selectedFile = files[selectedFileIndex];
            return (
              <TabPanel className='flex space-x-4' key={mutation}>
                <FormField
                  name={`translations.${selectedFileIndex}.file`}
                  render={({ field }) => (
                    <UploadDropzone
                      onDrop={handleFileUpload(field)}
                      singleFile={true}
                      className='h-full'
                      rootClassName='w-full'
                      subtitle={
                        field.value &&
                        typeof field.value !== 'string' &&
                        `${t('upload.dropZone.subtitle')} ${field.value.name}`
                      }
                    />
                  )}
                />

                {existingFile && typeof selectedFile.file === 'string' && (
                  <div className='w-full h-full md:w-[30%]'>
                    {/^image\//i.test(existingFile.type) ? (
                      <img
                        src={selectedFile.file}
                        className='rounded w-full h-auto'
                        alt='Preview'
                      />
                    ) : (
                      <video
                        src={selectedFile.file}
                        className='rounded w-full h-auto'
                        autoPlay={false}
                        controls
                      />
                    )}
                  </div>
                )}
              </TabPanel>
            );
          })}
        </TabPanels>
      </TabGroup>
      {uploadProgress !== null && (
        <div className='bg-input rounded-xl relative'>
          <div
            style={{ width: `${uploadProgress * 100}%` }}
            className='bg-primary absolute text-center rounded-xl h-full transition-[width]'
          ></div>
          <span className='text-primary-foreground text-center w-full block relative'>{`${Math.round(uploadProgress * 100)}%`}</span>
        </div>
      )}
      <Button
        loading={
          isSavingTranslations ||
          isSavingMainFile ||
          isDeletingTranslation ||
          isUpdatingMetadata
        }
        onClick={methods.handleSubmit(handleCreate)}
        className='w-full'
        disabled={files.length < availableMutations.length}
      >
        {t('upload.publish.btn')}
      </Button>
    </FormProvider>
  );
};
