import React from 'react';
import { useQuery } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';
import { FaMarker, FaUpload } from 'react-icons/fa6';
import { Link } from '@tanstack/react-router';
import { api } from '@admin/features/api';

import { EmptyListAlert } from '@admin/features/layout/components/PageTemplate/EmptyListAlert';
import { PageTemplate } from '@admin/features/layout/components/PageTemplate/PageTemplate';
import { Loader } from '@admin/features/theme/components/Loader/Loader';
import { DirectoryItem } from '@admin/features/assets/components/Items/Assets/Directory';
import { FileItem } from '@admin/features/assets/components/Items/Assets/File';
import { Button } from '@admin/features/theme/components/Button/Button';
import { isFile, isFolder } from '@admin/features/assets/utils/filter';
import { usePermissions } from '@admin/features/auth/usePermissions';

export const Folders: React.FC = () => {
  const { t } = useTranslation('assets');
  const { isEditor } = usePermissions();

  const queryKey = ['all-folders'];
  const { data, isError, isLoading, isSuccess } = useQuery({
    queryKey,
    queryFn: () => api.directoryControllerScanRootDir(),
  });

  return (
    <PageTemplate title={t('folders.all.title')}>
      <div className='flex flex-row-reverse gap-2'>
        {!isEditor && (
          <Link
            to='/assets/folder/$folderId/create'
            params={{ folderId: 'root' }}
          >
            <Button className='flex gap-2 items-center'>
              <FaMarker />
              {t('folders.all.create.btn')}
            </Button>
          </Link>
        )}
        <Link
          to='/assets/folder/$folderId/upload'
          params={{ folderId: 'root' }}
        >
          <Button className='flex gap-2 items-center'>
            <FaUpload />
            {t('folders.single.upload.btn')}
          </Button>
        </Link>
      </div>
      <div className='flex flex-col space-y-2 w-full'>
        {isLoading && <Loader />}
        {isError && (
          <h1 className='text-red-600'>{t('folders.fetch.error')}</h1>
        )}
        {isSuccess && data.data.length !== 0 ? (
          <div className='w-full space-y-2'>
            <div className='col-span-full grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 xl:grid-cols-5 2xl:grid-cols-7 gap-2'>
              {data.data.filter(isFolder).map((folder) => (
                <DirectoryItem
                  key={folder.id}
                  queryKey={queryKey}
                  {...folder}
                />
              ))}
            </div>
            <div className='col-span-full grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 xl:grid-cols-5 2xl:grid-cols-7 gap-2'>
              {data.data.filter(isFile).map((file) => (
                <FileItem
                  key={file.id}
                  queryKey={queryKey}
                  folderId={'root'}
                  {...file}
                />
              ))}
            </div>
          </div>
        ) : (
          <EmptyListAlert />
        )}
      </div>
    </PageTemplate>
  );
};
