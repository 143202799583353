import { BlogStatus } from '@admin/features/api/generated';
import { useFormattedDate } from '@admin/hooks/useFormattedDate';
import { cn } from '@admin/utils/cn';

type BlogStatusProps = {
  status: BlogStatus;
  publishedAt?: string;
};

const getBlogStatusStyles = (status: BlogStatus): string => {
  switch (status) {
    case BlogStatus.Draft:
      return 'bg-gray-100 text-gray-800 dark:text-gray-400 border-gray-500';
    case BlogStatus.Submitted:
      return 'bg-yellow-100 text-yellow-800 dark:text-yellow-300 border border-yellow-300';
    case BlogStatus.Approved:
      return 'bg-green-100 text-green-800 dark:text-green-400 border-green-400';
    case BlogStatus.Rejected:
      return 'bg-red-100 text-red-800 ark:text-red-400 border-red-400';
    case BlogStatus.Published:
      return 'bg-blue-100 text-blue-800 dark:text-blue-400 border-blue-400';
  }
};

export const BlogStatusBadge: React.FC<BlogStatusProps> = ({
  status,
  publishedAt,
}) => {
  const styles = cn(
    'text-xs font-medium px-2.5 py-0.5 rounded border dark:bg-gray-700',
    getBlogStatusStyles(status)
  );
  const publishedDate = useFormattedDate(publishedAt);

  return (
    <span className={styles}>
      {status} {publishedDate}
    </span>
  );
};
