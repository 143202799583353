import React from 'react';
import { Modal } from '@admin/features/theme/components/Modals/Modal';
import { FormProvider } from 'react-hook-form';
import { InputField } from '@admin/features/form/components/InputField';
import * as yup from 'yup';
import { useForm } from '@admin/hooks/useForm';
import { api } from '@admin/features/api';
import { useNotificationDispatch } from '@admin/features/layout/hooks/useNotificationDispatch';
import { useModal } from '@admin/features/layout/modals/useModal';
import { useBlogsTranslation } from '@admin/features/blogs/locales/useBlogsTranslation';
import { TagGroupResponseDto } from '@admin/features/api/generated';
import { useHandledMutation } from '@admin/hooks/useHandledMutation';
import { useQueryClient } from '@tanstack/react-query';

const schema = yup.object({
  name: yup.string().required(),
});
type FormValues = yup.InferType<typeof schema>;

export type TagGroupFormModalProps = {
  tagGroup?: TagGroupResponseDto;
};

export const TagGroupFormModal = ({ tagGroup }: TagGroupFormModalProps) => {
  const { t } = useBlogsTranslation();
  const { closeModal, openPreviousModal } = useModal();
  const createNotification = useNotificationDispatch();

  const methods = useForm({
    schema,
    defaultValues: schema.cast(
      tagGroup ?? {
        name: '',
      }
    ),
  });

  const queryClient = useQueryClient();
  const { handleSubmit } = methods;
  const { mutate, isPending } = useHandledMutation({
    mutationFn: (values: FormValues) =>
      tagGroup
        ? api.tagGroupControllerUpdate(tagGroup.id, {
            name: values.name,
          })
        : api.tagGroupControllerCreate(values),
    onSuccess: async () => {
      createNotification({
        variant: 'success',
        content: tagGroup
          ? t('editor.modals.update-group.success')
          : t('editor.modals.create-group.success'),
      });
      await queryClient.invalidateQueries({
        queryKey: ['tagControllerFindAllWithGroups'],
      });
      openPreviousModal();
    },
  });
  const submitCallback = handleSubmit((values: FormValues) => {
    mutate(values);
  });

  return (
    <Modal
      isOpen
      title={
        tagGroup
          ? t('editor.modals.update-tag-group.title', { name: tagGroup.name })
          : t('editor.modals.create-tag-group.title')
      }
      onClose={closeModal}
      onConfirm={submitCallback}
      onPrevious={openPreviousModal}
      closeOnOutsideClick
      loading={isPending}
    >
      <FormProvider {...methods}>
        <div className='flex h-full w-full flex-column justify-center items-center'>
          <InputField
            name='name'
            className='w-full'
            label={
              tagGroup
                ? t('editor.modals.update-tag-group.label')
                : t('editor.modals.create-tag-group.label')
            }
          />
        </div>
      </FormProvider>
    </Modal>
  );
};
