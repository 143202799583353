import { TagGroupResponseDto } from '@admin/features/api/generated';
import { Modal } from '@admin/features/theme/components/Modals/Modal';
import React, { useEffect } from 'react';
import { api } from '@admin/features/api';
import { useModal } from '@admin/features/layout/modals/useModal';
import { useBlogsTranslation } from '@admin/features/blogs/locales/useBlogsTranslation';
import { useDispatch } from '@admin/store/store';
import { useHandledMutation } from '@admin/hooks/useHandledMutation';
import { useQueryClient } from '@tanstack/react-query';

export type RemoveTagGroupModalProps = {
  tagGroup: TagGroupResponseDto;
};

export const RemoveTagGroupModal: React.FC<RemoveTagGroupModalProps> = ({
  tagGroup,
}) => {
  const { t } = useBlogsTranslation();
  const { openPreviousModal, closeModal } = useModal();
  const dispatch = useDispatch();

  const queryClient = useQueryClient();
  const { isSuccess, mutate } = useHandledMutation({
    mutationFn: () => api.tagGroupControllerRemove(tagGroup.id),
    onSuccess: async () => {
      await queryClient.invalidateQueries({
        queryKey: ['tagControllerFindAllWithGroups'],
      });
    },
  });

  const confirmHandler = (): void => {
    mutate();
  };

  useEffect(() => {
    if (isSuccess) {
      openPreviousModal();
    }
  }, [dispatch, isSuccess, openPreviousModal]);

  return (
    <Modal
      isOpen
      title={t('editor.modals.removeTagGroup.title')}
      onClose={closeModal}
      onConfirm={confirmHandler}
      onPrevious={openPreviousModal}
      closeOnOutsideClick
    >
      {t('editor.modals.removeTagGroup.message', { name: tagGroup.name })}
    </Modal>
  );
};
